@import "~antd/dist/antd.css";
@import "//vjs.zencdn.net/7.0/video-js.min.css";

@font-face {
    font-family: 'BakerieSmooth';
    src: local('BakerieSmooth'), url(../../src/fonts/BakerieSmooth.otf) format('opentype');
}

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(../../src/fonts/quicksand-medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../../src/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url(../../src/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

/** make sure the canvas cannot exceed 100% width, which would create a x-scrollbar */
html,
body,
#root,
[data-reactroot],
.content,
#root>div:first-child {
    height: 100%;
    min-height: 100%;
    background: #f8f8f8;
    font-family: Poppins !important;
    color: #000;
}

.bakerie-font {
    font-family: BakerieSmooth !important;
}

.bakerie-font .ant-avatar-string {
    font-family: BakerieSmooth !important;
    margin-top: 6px !important;
}

.content {
    min-height: calc(100vh - 213px);
    margin-bottom: 60px;
}

.read-only .content {
    min-height: calc(100vh - 264px);
    margin-bottom: 60px;
}

.home-page .content {
    min-height: calc(100vh - 197px);
    margin-bottom: 60px;
}

h1 {
    font-size: 25px !important;
}

.footer {
    padding: 16px 20px !important;
    flex-shrink: 0;
    background: #fff !important;
    color: grey !important;
    height: 70px !important;
}

.footer-light {
    flex-shrink: 0;
    background: #fff !important;
    color: #333;
    height: 70px !important;
}

.cursor-default:hover {
    cursor: default !important;
}

.ant-tabs,
.ant-form-item,
.ant-select,
.ant-steps,
.ant-btn,
.ant-input,
.ant-menu-item,
.ant-menu-submenu-title,
.ant-avatar,
.ant-popover,
.ant-tooltip,
.ant-radio-wrapper,
.ant-checkbox-group,
.ant-radio-group {
    font-family: Poppins !important;
    color: #000;
}

.ant-tabs-tab {
    color: #777 !important;
    font-weight: bold;
}

.ant-steps-item-finish>.ant-steps-item-content>.ant-steps-item-title {
    font-family: Poppins !important;
    color: #000;
}

.ant-steps-item-process>.ant-steps-item-content>.ant-steps-item-title {
    font-family: Poppins !important;
    color: #000;
    font-weight: bold;
}

.ant-steps-item-title {
    word-break: break-word;
}

.ant-avatar {
    color: #fff;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.background-ant-lg {
    background: #f7fafa !important;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.dot-background {
    background-image: url(../../public/dot-background.png);
}

.grey-gradient {
    background-color: #fff;
    background-image: linear-gradient(to bottom right, white, white, rgb(240, 240, 240)) !important;
}

.admin-banner {
    background-image: url(../../public/admin-banner.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 314px;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-27 {
    font-size: 27px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-35 {
    font-size: 35px !important;
}

.font-38 {
    font-size: 38px !important;
}

.font-40 {
    font-size: 40px !important;
}

.font-42 {
    font-size: 42px !important;
}

.font-50 {
    font-size: 50px !important;
}

.font-60 {
    font-size: 60px !important;
}

.react-joyride__tooltip {
    padding: 16px !important;
    width: 400px !important;
}

.text-muted-lighter {
    color: #7B7B7B !important;
}

.text-muted {
    color: #787878 !important;
}

.text-muted-darker {
    color: #595959 !important;
}

.hover-text-cyan-all:hover * {
    color: #1098f7 !important;
}

.font-black {
    color: #000 !important;
}

.font-lb {
    color: #333;
}

.font-fff {
    color: #fff;
}

.font-fff-important {
    color: #fff !important;
}

.button-font-fff {
    color: #fff !important;
}

.font-lg {
    color: #fafafa;
}

.font-grey {
    color: #d2d2d2;
}

.font-green {
    color: #3fa672 !important;
}

.font-red {
    color: #f5222d !important;
}

.font-cyan {
    color: #1098f7 !important;
}

.font-yellow {
    color: #faa916 !important;
}

.background-grey-cyan {
    background: #ecf3f3 !important;
}

.lh-30 {
    line-height: 30px !important;
}

.text-muted {
    color: #777;
}

.text-more-muted {
    color: #aaa;
}

.transparent-btn {
    padding: 0px !important;
    border: 0px transparent !important;
    background: transparent !important;
}

.transparent {
    background: transparent !important;
}

.arrow-mt-1 .arrow {
    margin-top: 8px;
}

.font-500 {
    font-weight: 500;
}

.font-bold {
    font-weight: bold;
}

.w-10 {
    width: 10%;
    min-width: 10%;
}

.w-20 {
    width: 20%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.h-50 {
    height: 50%;
}

.h-100 {
    height: 100%;
}


.h-20px {
    height: 20px;
}

.h-30px {
    height: 30px;
}

.h-50px {
    height: 50px;
}

.h-100px {
    height: 100px;
}

.h-300 {
    height: 300px;
}

.w-30px {
    width: 30px;
}

.w-40px {
    width: 40px;
}

.w-50px {
    width: 50px;
}

.w-100px {
    width: 100px;
}

.w-150px {
    width: 150px;
}

.w-200px {
    width: 200px;
}

.w-230px {
    width: 200px;
    max-width: 200px;
}

.w-300 {
    width: 300px;
}

.w-350 {
    width: 350px;
}


.w-25-p {
    width: 25%;
}

.w-33-p {
    width: 34%;
}

.w-33-pct {
    width: 33%;
}

.w-66-p {
    width: 66%;
}

.w-66-p-minus-35 {
    width: calc(66% - 35px);
}

.w-70-p {
    width: 70%;
}

.w-75-p {
    width: 75%;
}

.mw-150px {
    min-width: 150px;
    max-width: 150px;
}

.mw-200px {
    min-width: 200px;
    max-width: 200px;
}

.w-100-minus-30px {
    width: calc(100% - 30px);
}

.lh-0 {
    line-height: 0px;
}

.t-8 {
    top: 9px;
}

.t-67 {
    top: 67px;
}

.t-167 {
    top: 167px;
}

.border-bottom-vl {
    border-bottom: 1px solid #fafafa;
}

.t-minus-2 {
    top: -1px;
}

.cyan-hover:hover {
    background: #36cfc9 !important;
}

.light-cyan-hover:hover {
    background: #b3e9ff !important;
}

.selected-cyan {
    background: #b3e9ff !important;
}

.black-outline-btn {
    color: #668 !important;
    border-color: #668 !important;
}

.yellow-gradient {
    background: #faa916;
    background-image: linear-gradient(to bottom right, #ffd666, #d48806);
}


.lh-normal {
    line-height: normal;
}

.pt-6px {
    padding-top: 6px;
}

.va-2 {
    vertical-align: 2px;
}

.va-2-important {
    vertical-align: 2px !important;
}

.va-3 {
    vertical-align: 2px;
}


.va-minus-1 {
    vertical-align: -1px;
}


.va-minus-2 {
    vertical-align: -2px;
}

.va-minus-4 {
    vertical-align: -4px;
}


.va-minus-13 {
    vertical-align: -13px;
}

.avatar-vlg {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px !important;
}

.ant-form label {
    font-size: 20px !important;
    font-weight: bold;
}

.ant-form label.ant-radio-button-wrapper {
    font-weight: normal !important;
}

.ant-form label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    font-weight: bold !important;
}

.ant-radio-wrapper {
    font-weight: normal !important;
}


.ant-form-item-label>label::after {
    content: none !important;
    top: 0px !important;
    margin: 0px !important;
}

.br-tr-2 {
    border-top-right-radius: 2px;
}

.br-br-2 {
    border-bottom-right-radius: 2px;
}

.br-tr-100 {
    border-top-right-radius: 100px;
}

.br-br-100 {
    border-bottom-right-radius: 100px;
}

.z-hover:hover {
    z-index: 1;
}

.scale-hover-child {
    transition: 0.3s ease;
}

.scale-hover-parent:hover .scale-hover-child {
    transform: scale(1.5, 1.5);
}

.fixed-left {
    position: fixed;
    left: 16px;
    top: 87px;
    bottom: 24px;
}

.height-30px {
    height: 30px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-color: #1098f7 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #b3e9ff !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: #1098f7 !important;
}

.border-white {
    border: 1px solid white;
}

.w-100px {
    width: 100px;
}

.page-break-after-always {
    page-break-after: always;
}

.page-break-after-auto {
    page-break-after: auto;
}

@media print {

    html,
    body {
        height: auto !important;
        page-break-after: avoid;
        page-break-before: avoid;
        line-height: 1 !important;
    }
}

label.ant-checkbox-wrapper:after {
    content: "";
    margin: 0px;
}

.p-0 .ant-checkbox-wrapper+span,
.ant-checkbox+span {
    padding: 0px;
}

.va-minus-3 {
    vertical-align: -3px;
}

.va-minus-5 {
    vertical-align: -5px;
}


.fw-500 {
    font-weight: 500;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.focus-show:focus {
    opacity: 1;
    pointer-events: all;
}

.display-table {
    display: table;
}

.display-table-head {
    display: table-header-group;
}

.display-table-body {
    display: table-row-group;
}

.table-cell {
    display: table-cell;
}

.table-row {
    display: table-row;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.va-tt {
    vertical-align: text-top;
}

.pt-180 {
    padding-top: 180px;
}

.max-w-50 {
    max-width: 50%;
}

.max-w-75 {
    max-width: 75%;
}

.max-w-100 {
    max-width: 100%;
}

.max-w-110 {
    max-width: 110px !important;
}

.max-w-150 {
    max-width: 150px;
}

.max-w-500 {
    max-width: 500px;
}

.max-w-100px {
    max-width: 100px;
}

.max-w-180px {
    max-width: 180px;
}

.max-w-650 {
    max-width: 650px;
}

.max-w-670 {
    max-width: 670px;
}

.max-w-1200 {
    max-width: 1100px;
}

.w-670 {
    width: 670px;
}

.w-700 {
    width: 700px;
}

.max-w-700 {
    max-width: 700px;
}

.input-cursor-display-none {
    caret-color: transparent !important;
}

.collapse-panel-w-100 .ant-collapse-header {
    width: 100% !important;
}

.min-w-1024 {
    min-width: 1024px !important;
}

.mt-10px {
    margin-top: 10px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-200 {
    margin-top: 200px;
}

.font-34 {
    font-size: 34px;
}

.mt-200 {
    margin-top: 200px;
}

.font-50 {
    font-size: 50px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #1098f7 !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #1098f7 !important;
}


.home-main-block::before {
    z-index: 0;
    content: '';
    /**background-image: url(https://www.algolia.com/static_assets/images/v3/homepage/hero-475b7915.svg);*/
    padding-bottom: 800px;
    width: 100%;
    background-size: 100% 100%;
    display: block;
    position: absolute;
    left: 0;
    top: -40px;
}

.confetti-bkg {
    background-image: url('../../public/light-confetti-bkg.png');
    background-size: cover;
}

.mt-40 {
    margin-top: 40px;
}

.pt-40 {
    padding-top: 40px;
}

.alert-vlg .ant-alert-message {
    font-size: 20px !important;
}

.background-coral {
    background: #FF5757 !important;
}

.alert-vlg .ant-alert-icon {
    font-size: 24px !important;
    font-weight: bold !important;
    color: #1098f7 !important;
}

.alert-vlg.ant-alert {
    padding-left: 50px !important;
}

.ant-alert-info {
    background-color: #e6fffb !important;
    border: none !important;
}

.react-joyride__tooltip button {
    font-weight: bold;
    border-radius: 100px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}


.react-joyride__tooltip button:hover {
    background: #36cfc9 !important;
}

.react-joyride__tooltip button:focus {
    outline: none;
}

.react-joyride__tooltip button[aria-label="Back"] {
    border: 1px solid #1098f7 !important;
    color: #1098f7 !important;
}

.react-joyride__tooltip button[aria-label="Back"]:hover {
    border: 1px solid #1098f7 !important;
    background: #36cfc9 !important;
    color: #fff !important;
}

.react-joyride__tooltip button[aria-label="Skip"]:hover {
    background: #e8e8e8 !important;
}

.react-joyride__tooltip button[aria-label="Close"] {
    padding: 8px !important;
    margin-top: 8px !important;
    margin-right: 8px !important;
}

.react-joyride__tooltip button[aria-label="Close"]:hover {
    background: #f1f1f1 !important;
}

.skew::before {
    content: '';
    transform: skew(-12deg);
    background: #f6f9fc;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.third-block::before {
    z-index: 0;
    content: '';
    /**background-image: url(https://www.algolia.com/static_assets/images/v3/enterprise/hero-6661af98.svg);*/
    padding-bottom: 300px;
    width: 100%;
    background-size: 100% 100%;
    display: block;
    position: absolute;
    left: 0;
    top: -337px;
}


.footer-block::before {
    z-index: 0;
    content: '';
    /**background-image: url(https://www.algolia.com/static_assets/images/v3/shared/footer-waves-9c15c6b7.svg);*/
    padding-bottom: 200px;
    width: 100%;
    background-size: 100% 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 120px;
}

.min-w-container {
    min-width: 1024px;
    overflow-x: auto;
}

.mt-20 {
    margin-top: 20px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-200 {
    margin-bottom: 200px;
}

.mb-12px {
    margin-bottom: 12px;
}

.va-1 {
    vertical-align: 1px;
}

.va-minus-11 {
    vertical-align: -11px !important;
}

.mt-minus-2 {
    margin-top: -2px;
}

.mt-1px {
    margin-top: 1px;
}

.mt-2px {
    margin-top: 2px;
}

.mt-3px {
    margin-top: 3px;
}

.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 8px !important;
}

.m-2 {
    margin: 16px !important;
}

.m-3 {
    margin: 24px !important;
}

.m-4 {
    margin: 32px !important;
}


.mr-0 {
    margin-right: 0px !important;
}

.mr-05 {
    margin-right: 4px !important;
}

.mr-1 {
    margin-right: 8px !important;
}

.mr-1-half {
    margin-right: 12px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-4px {
    margin-right: 4px !important;
}

.mr-2 {
    margin-right: 16px !important;
}

.mr-3 {
    margin-right: 24px !important;
}

.mr-4 {
    margin-right: 32px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-05 {
    margin-left: 4px !important;
}

.ml-1 {
    margin-left: 8px !important;
}

.ml-2 {
    margin-left: 16px !important;
}

.ml-3 {
    margin-left: 24px !important;
}

.ml-4 {
    margin-left: 32px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-05 {
    margin-top: 4px !important;
}

.mt-6px {
    margin-top: 6px !important;
}

.mt-1 {
    margin-top: 8px !important;
}

.mt-2 {
    margin-top: 16px !important;
}

.mt-3 {
    margin-top: 24px !important;
}

.mt-4 {
    margin-top: 32px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-05 {
    margin-bottom: 4px !important;
}

.mb-1 {
    margin-bottom: 8px !important;
}

.mb-2 {
    margin-bottom: 16px !important;
}

.mb-3 {
    margin-bottom: 24px !important;
}

.mb-4 {
    margin-bottom: 32px !important;
}

.p-0 {
    padding: 0px !important;
}

.p-05 {
    padding: 4px !important;
}

.p-1 {
    padding: 8px !important;
}

.p-2 {
    padding: 16px !important;
}

.p-3 {
    padding: 24px !important;
}

.p-4 {
    padding: 32px !important;
}

.p-5 {
    padding: 40px !important;
}

.p-6 {
    padding: 48px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 8px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-12px {
    padding-right: 12px !important;
}

.pr-2 {
    padding-right: 16px !important;
}

.pr-3 {
    padding-right: 24px !important;
}

.pr-4 {
    padding-right: 32px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 8px !important;
}

.pl-2 {
    padding-left: 16px !important;
}

.pl-3 {
    padding-left: 24px !important;
}

.pl-4 {
    padding-left: 32px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 8px !important;
}

.pt-2 {
    padding-top: 16px !important;
}

.pt-3 {
    padding-top: 24px !important;
}

.pt-4 {
    padding-top: 32px !important;
}

.pt-45 {
    padding-top: 48px !important;
}

.pt-5 {
    padding-top: 64px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-05 {
    padding-bottom: 4px !important;
}

.pt-05 {
    padding-top: 4px !important;
}

.pb-1 {
    padding-bottom: 8px !important;
}

.pb-2 {
    padding-bottom: 16px !important;
}

.pb-3 {
    padding-bottom: 24px !important;
}

.pb-4 {
    padding-bottom: 32px !important;
}

.m-minus-1 {
    margin: -8px !important;
}

.negate-popover-title-margin {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -5px;
}

.w-125 {
    width: 125px;
}

.w-200 {
    width: 200px;
}

.w-400 {
    width: 400px;
}

.w-452 {
    width: 452px;
}

.w-500 {
    width: 500px;
}

.w-650 {
    width: 650px;
}

.w-680 {
    width: 680px;
}

.w-750 {
    width: 750px;
}

.w-800 {
    width: 800px;
}

.min-w-800 {
    min-width: 800px;
}

.min-w-1120 {
    min-width: 1120px;
}

.max-w-1120 {
    max-width: 1120px;
}

.w-1000 {
    width: 1000px;
}

.w-1024 {
    width: 1024px;
}

.w-1200 {
    width: 1200px;
}

.w-1250 {
    width: 1250px;
}

.w-1280 {
    width: 1280px;
}

.pl-1px-important {
    padding-left: 1px !important;
}

.pr-1px-important {
    padding-right: 1px !important;
}

.no-resize {
    resize: none !important;
}

.no-text-highlight {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-content-padding .ant-collapse-content-box {
    padding: 0px !important;
}

.side-menu-item-hover:hover {
    background: #b3e9ff;
}

.stroke-cyan .ant-progress-circle-path {
    stroke: #1098f7 !important;
}

.stroke-cyan .ant-progress-inner .ant-progress-text {
    color: #1098f7 !important;
}

.sign-in-gradient {
    background: #fff;
    background: linear-gradient(to left top, #1098f7 0, #722ed1 50%, #faa916), linear-gradient(#000, #000);
}

.mw-1280 {
    max-width: 1280px;
}

.m-lr-auto {
    margin-left: auto;
    margin-right: auto;
}

.h-150 {
    height: 150px;
}

.h-170 {
    height: 170px;
}

.h-175 {
    height: 175px;
}

.h-200 {
    height: 230px;
}

.h-290 {
    height: 290px;
}

.p-add {
    padding: 20px;
}

.display-none {
    display: none;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
    -ms-user-select: none;
    user-select: none;
}

.ml-2px {
    margin-left: 2px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.m-lr-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mt-minus-3 {
    margin-top: -3px;
}

.zi-1 {
    z-index: 1;
}

.zi-2 {
    z-index: 2;
}

.zi-3 {
    z-index: 3;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-fff {
    color: #fff;
}

.vjs-seek-to-live-control.vjs-control {
    display: none !important;
}


.background-fff {
    background: #fff;
}

.background-fff-important {
    background: #fff !important;
}

.background-blue {
    background: #00284d;
}


.border {
    border: 1px solid #d2d2d2;
}

.border-left {
    border-left: 1px solid #d2d2d2;
}

.border-right {
    border-right: 1px solid #d2d2d2;
}

.border-top {
    border-top: 1px solid #d2d2d2;
}

.border-bottom {
    border-bottom: 1px solid #d2d2d2;
}

.border-bottom-light {
    border-bottom: 1px solid #d2d2d2;
}

.border-bottom-lighter {
    border-bottom: 1px solid #e8e8e8;
}

.border-top-lighter {
    border-top: 1px solid #e8e8e8;
}

.border-left-lighter {
    border-left: 1px solid #e8e8e8;
}

.border-right-lighter {
    border-right: 1px solid #e8e8e8;
}

.border-bottom-none {
    border-bottom: none !important;
}

.border-right-none {
    border-right: none;
}

.border-bottom-dark {
    border-bottom: 1px solid #d2d2d2;
}

.border-right-dark {
    border-right: 1px solid #d2d2d2;
}

.border-dashed {
    border: 1px solid #d2d2d2;
}

.schedule-notification {
    position: fixed;
    top: 132px;
    left: 0px;
    width: 250px;
    height: 100%;
}

.alternate-color-table .event-container-placed:nth-of-type(odd) {
    background-color: #f7f7f7;
}

.mh-100 {
    max-height: 100%;
}

.mh-150 {
    max-height: 150px;
}

.mh-300 {
    max-height: 300px;
}

.ant-menu-item {
    text-overflow: ellipsis;
}

.inline-block .ant-collapse-header {
    display: inline-block;
}

.cursor-grab:hover {
    cursor: grab;
}

.ml-2px {
    margin-left: 2px;
}

.va-middle {
    vertical-align: middle;
}

.va-top {
    vertical-align: top !important;
}

.va-bottom {
    vertical-align: bottom;
}

.va-textb {
    vertical-align: text-bottom;
}

.va-text-top {
    vertical-align: text-top;
}

.h-64 {
    height: 64px;
}

.va-minus-8 {
    vertical-align: -8px;
}

.va-minus-16 {
    vertical-align: -16px;
}

.negate-popover-padding {
    margin: -12px -16px;
}

.blue-pill {
    background: #1890ff;
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
}

.review-link {
    color: #314651;
}

.review-link:hover {
    color: #314651;
    cursor: pointer;
}

.no-popover-arrow .ant-popover-arrow {
    display: none;
}

.grey-hover-active {
    background: #f1f1f1;
}

.grey-hover:hover {
    background: #f1f1f1;
}

.grey-hover-parent:hover .grey-hover-child {
    background: #f1f1f1;
}

.grey-hover-parent .ant-collapse-header:hover {
    background: #f8f8f8;
}

.grey-hover-important:hover,
.grey-hover-important:active {
    background: #f1f1f1 !important;
}

.filter-collapse {
    display: inline-block
}

.font-dark-grey {
    color: grey;
}

.font-dark-grey:hover {
    color: grey !important;
}

.filter-collapse .ant-collapse-header {
    padding: 0px !important;
}

.filter-panel {
    border-bottom: 0px transparent !important;
}

.filter-panel .ant-collapse-content {
    width: 436px;
    margin-left: -376px;
    padding: 0px;
}

.filter-panel .ant-collapse-content .ant-collapse-content-box {
    padding-top: 0px !important;
    padding-bottom: 0px;
    margin-top: 16px;
}

.uppercase {
    text-transform: uppercase;
}

.border-box {
    box-sizing: border-box;
    ms-box-sizing: border-box;
    webkit-box-sizing: border-box;
    moz-box-sizing: border-box;
}

.layout-content {
    margin: 40px 32px 0px !important;
    overflow: initial !important;
}

.layout-content-no-mlr {
    margin: 40px 0px 0px !important;
    overflow: initial !important;
}

.layout-content-extra-pt {
    margin: 85px 32px 0px !important;
    overflow: initial !important;
}

.layout-content-no-mt {
    margin: 0px 32px 0px !important;
    overflow: initial !important;
}

.layout-header-mt {
    margin-top: 83px;
}

.layout-header-mt-less {
    margin-top: 82px;
}

.read-only .layout-header-mt {
    margin-top: 134px;
}

.footer {
    clear: both;
    position: relative;
    height: 64px;
    background: #001529;
}

.clear {
    clear: both;
}

.relative {
    position: relative;
}

.t-3 {
    top: 24px;
}

.absolute {
    position: absolute;
}

.absolute-tl {
    position: absolute;
    top: 0px;
    left: 0px;
}

.absolute-tr {
    position: absolute;
    top: 0px;
    right: 0px;
}

.absolute-bl {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.absolute-br {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.select-suffix {
    position: absolute;
    right: 22px;
    color: #bfbfbf;
    pointer-events: none;
}

.tabs-vlg .ant-tabs-tab {
    font-size: 20px !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.t-0 {
    top: 0px;
}

.l-0 {
    left: 0px
}

.cyan-btn:hover {
    background: #36cfc9 !important;
}

.cyan-btn:focus {
    background: #36cfc9 !important;
}

.h-100vh {
    height: 100vh;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-visible-important {
    overflow: visible !important;
}

.overflow-y-scroll {
    overflow-y: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.background-light-grey {
    background: #f1f1f1;
}

.background-grey {
    background: #d2d2d2;
}

.background-grey-op50 {
    background: rgba(245, 245, 245, .5)
}

.shadow {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
}

.light-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.ant-shadow {
    box-shadow: rgba(35, 49, 66, 0.15) 0px 6px 18px 0px;
    border: 1px solid transparent;
}

.ant-shadow-more-hover:hover {
    box-shadow: rgba(35, 49, 66, 0.22) 0px 8px 18px 0px;
    border: 1px solid #233142 !important;
}

.ant-shadow-light {
    box-shadow: rgba(35, 49, 66, 0.08) 0px 4px 8px 0px;
}

.purple-border-hover:hover {
    box-shadow: inset 0 0 0 1px rgba(35, 49, 66) !important;
}

.ant-shadow-small {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15)
}

.ant-shadow-transparent-top {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
}

.ant-popover {
    box-shadow: none !important;
}

.ant-popover-content {
    box-shadow: 0 18px 18px rgb(0 0 0 / 15%) !important;
}

.standard-tooltip-card {
    width: 270px;
}

.standard-tooltip-title {
    max-height: 140px;
    overflow-y: auto;
}

.notebook-popover .ant-popover-content {
    background: transparent !important;
    box-shadow: none !important;
}

.notebook-popover .ant-popover-inner {
    background: transparent !important;
    box-shadow: none !important;
}

.notebook-popover .ant-popover-arrow {
    background: transparent !important;
    box-shadow: none !important;
}

.ant-header-shadow {
    box-shadow: 0 2px 8px #f0f1f2;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6fffb !important;
}

.ant-avatar-vs>* {
    line-height: 20px !important;
}

.menu-vlg.ant-menu-inline>.ant-menu-item {
    height: 60px;
    line-height: 60px;
}

.menu-vlg-2 .ant-menu-item {
    height: 60px !important;
    line-height: 60px !important;
}

.side-menu-item-active {
    border: 1px solid #233142 !important;
}

.side-menu-item-active:hover {
    border: 1px solid #233142 !important;
}

.text-blue {
    color: #1890ff;
}

.form-font-black {
    color: rgba(0, 0, 0, 0.85) !important;
}

.text-darker-blue {
    color: #096dd9;
}

.link-blue {
    color: #1890ff;
    cursor: pointer;
}

.link-blue:hover {
    color: #40a9ff;
}

.lesson-plan-modal h2 {
    font-size: 24px;
    font-weight: bold;
}

.lesson-plan-modal {
    font-size: 20px;
}

.btn {
    border-radius: 100px;
}

.ant-select-dropdown-menu-item-group-title {
    font-size: 16px !important;
    color: #000 !important;
}

.ant-switch-checked {
    background-color: #1098f7 !important;
}

.circle-icon-btn-lg {
    height: 50px;
    width: 50px;
    line-height: 50px !important;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    border-radius: 50% !important;
}

.ant-table-column-title {
    font-size: 16px;
    font-weight: bold;
}

.ant-table-row td {
    font-size: 16px !important;
}

.hover-table tr {
    cursor: pointer !important;
}

.yellow-btn {
    background: #faa916 !important;
    border-color: #faa916 !important;
    color: #fff !important;
}

.yellow-btn:hover {
    background: #ffc53d !important;
    border-color: #ffc53d !important;
    color: #fff !important;
}

.blue-btn {
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: #fff !important;
}

.blue-btn:hover {
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: #fff !important;
}

.purple-btn {
    background: #722ed1 !important;
    border-color: #722ed1 !important;
    color: #fff !important;
}

.purple-btn:hover {
    background: #722ed1 !important;
    border-color: #722ed1 !important;
    color: #fff !important;
}

.pink-btn {
    background: #eb2f96 !important;
    border-color: #eb2f96 !important;
    color: #fff !important;
}

.pink-btn:hover {
    background: #eb2f96 !important;
    border-color: #eb2f96 !important;
    color: #fff !important;
}

.cyan-btn-border-left {
    border: none;
    border-left: 5px solid #1098f7 !important;
}

.cyan-btn-border-left:hover {
    border: none !important;
    border-left: 5px solid #1098f7 !important;
}

.blue-btn-border-left {
    border: none;
    border-left: 5px solid #1890ff !important;
}

.blue-btn-border-left:hover {
    border: none !important;
    border-left: 5px solid #1890ff !important;
}

.purple-btn-border-left {
    border: none;
    border-left: 5px solid #722ed1 !important;
}

.purple-btn-border-left:hover {
    border: none !important;
    border-left: 5px solid #722ed1 !important;
}

.pink-btn-border-left {
    border: none;
    border-left: 5px solid #eb2f96 !important;
}

.pink-btn-border-left:hover {
    border: none !important;
    border-left: 5px solid #eb2f96 !important;
}


.red-btn-border-left {
    border-left: 5px solid #f5222d !important;
}

.red-btn-border-left:hover {
    border-color: #f5222d !important;
}


.yellow-btn-border-left {
    border-left: 5px solid #faa916 !important;
}

.yellow-btn-border-left:hover {
    border-color: #faa916 !important;
}


.green-btn-border-left {
    border-left: 5px solid #3fa672 !important;
}

.green-btn-border-left:hover {
    border-color: #3fa672 !important;
}

.red-text {
    color: #f5222d !important;
}

.yellow-text {
    color: #fa8c16 !important;
}

.green-text {
    color: #3fa672 !important;
}

.purple-text {
    color: #722ed1 !important;
}

.pink-text {
    color: #eb2f96 !important;
}



.ant-tabs-bar {
    margin-bottom: 0px !important;
    background: transparent !important;
}

.ant-collapse-borderless {
    background: #fff !important;
}

.background-ant-lg {
    background: #f5f5f5;
}

.w-33 {
    width: 33px;
}

.large-tab .ant-tabs-nav {
    font-size: 20px;
    font-weight: bold;
}

.r-minus-35 {
    right: -35px;
}

.w-75-p-minus-35 {
    width: calc(75% - 35px);
}

label {
    padding: 0px !important;
    /* ant label has no padding */
}

.custom-card-container {
    display: inline-block;
    border: transparent;
    overflow: hidden;
}

.ant-tabs-tab-active {
    font-weight: bold !important;
    /**background: #e6f7ff !important;*/
}

.ant-select-dropdown-menu {
    text-align: left;
}

.ant-select-very-large,
.ant-select-very-large .ant-select-selection--single {
    height: 42px !important;
}

.ant-select-very-large .ant-select-selection__rendered {
    margin-top: 4px;
}

.ant-select-very-large .ant-select-selection__placeholder {
    height: 21px !important;
    line-height: 21px !important;
}

.ant-select-very-large .ant-select-selection-selected-value {
    width: 100%;
    padding-right: 32px;
}

.ant-select-very-large-multiple .ant-select-selection__choice {
    height: 100% !important;
    padding-top: 6px !important;
}

.lh-41 {
    line-height: 41px !important;
}

.btn-very-large {
    height: 45px !important;
    line-height: 45px !important;
    font-size: 18px !important;
}

.table-mouse-hover table tr:hover {
    cursor: pointer;
}

.ant-card-body {
    padding: 15px 24px !important;
}

.ant-card-bordered {
    box-shadow: rgba(35, 49, 66, 0.15) 0px 6px 18px 0px !important;
    border: 1px solid transparent !important;
    border-radius: 15px !important;
}

.ant-input-search>.ant-input-suffix>.ant-input-search-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.text-fff {
    color: #fff !important;
}

.text-success {
    color: #389e0d !important;
}

.text-danger {
    color: #ff4d4f !important;
}

.text-warning {
    color: #faa916 !important;
}

.text-primary {
    color: #1890ff !important;
}

.text-cyan {
    color: #1098f7 !important;
}

.background-red {
    background: #ff4d4f !important;
}

.background-purple {
    background: #722ed1;
}

.background-pink {
    background: #eb2f96;
}

.background-l-pink {
    background: #f759ab;
}

.background-green {
    background: #3fa672 !important;
}

.background-orange {
    background: #ffa940 !important;
}

.background-dark-grey {
    background: #595959 !important;
}

.background-light-cyan {
    background: #36cfc9 !important;
}

.background-orange-red {
    background: #fa541c;
}

.background-logo-orange {
    background: #FF5F34;
}

.font-background-logo-orange {
    color: #FF5F34 !important;
}

.tier-block {
    background: #1098f7;
    border: 1px solid #08979c;
    height: 25px;
    width: 25px;
}

.border-left-red {
    border-left: 5px solid #ff4d4f !important;
}

.background-light-cyan-gradient {
    background-image: linear-gradient(to top, #f0f7f7, #fff);
}

.background-cyan {
    background: #1098f7 !important;
}

.background-blue {
    background: #1890ff !important;
}

.background-light-blue {
    background: #40a9ff !important;
}

.row-grey-cyan-hover:hover {
    background: #ecf3f3 !important;
}

.border-ge {
    border-color: #36cfc9 !important;
}

.border-se {
    border-color: #faa916 !important;
}

.text-ge {
    color: #36cfc9 !important;
}

.text-se {
    color: #faa916 !important;
}

.drop-container.background-ge.border-bottom {
    border-color: #4870f7;
}

.drop-container.background-se.border-bottom {
    border-color: #ff9c24;
}

.background-ge {
    background: #fff !important;
}

.background-se {
    background: #fff !important;
}

.drop-container {
    background: #ffffff;
}

.drop-container.background-ge .drop-zone.is-over {
    background: #85a5ff !important;
}

.drop-container.background-se .drop-zone.is-over {
    background: #ffc069 !important;
}

.drop-container .drop-zone.is-over {
    background: #d2d2d2;
}

.background-lge {
    background: #fff !important;
}

.background-lse {
    background: #fff !important;
}

.border-lge {}

.border-lse {}

.border-teacher {
    background: #d3adf7 !important;
    color: #233142 !important;
}

.border-lge:hover {}

.border-lse:hover {}

.border-teacher:hover {}

.background-l-lge {
    background: #b3e9ff;
    color: #002329 !important;
}

.background-l-lse {
    background: #ffe58f;
    color: #613400 !important;
}

.background-m-lge {
    background: #adc6ff;
}

.background-m-lse {
    background: #ffd591;
}

.special-education-calendar-footer {}

.general-education-calendar-footer {
    background: #08979c;
}

.teacher-education-calendar-footer {}

.ant-btn.background-m-lge:hover {
    background: #d6e4ff;
}

.ant-btn.background-m-lse:hover {
    background: #ffe7ba;
    border-color: #ffc069;
    color: #fa8c16;
}

.ant-modal {
    color: #000 !important;
}

.border-success {
    border-color: #3fa672 !important;
}

.border-error {
    border-color: #f5222d !important;
}

.bg-light-green {
    background: #f6ffed !important;
}

.bg-light-red {
    background: #fff1f0 !important;
}

.border-cyan {
    border: 1px solid #1098f7;
    border-radius: 0.333rem;
}

.border-dashed-3px {
    border: 3px dashed #f1f1f1;
}

.w-100-minus-3 {
    width: calc(100% - 8px);
}

.h-100-minus-3 {
    height: calc(100% - 4px);
}

.no-ink .ant-anchor-ink {
    display: none;
}

.no-ink.ant-anchor-wrapper {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.ant-anchor {
    color: #000 !important;
}

.h-500 {
    height: 500px;
}

.min-h-100 {
    min-height: 100%;
}

.min-h-300 {
    height: 300px;
    min-height: 300px;
}

.space-between {
    justify-content: space-between !important;
}

.drop-container {
    transition: all .5s ease;
}

.drop-zone {
    background: inherit;
}

.drag-ge {
    background: #85a5ff;
    border-bottom: 2px solid #d6e4ff;
    border-right: 1px solid #d6e4ff;
    border-left: 1px solid #d6e4ff;
    border-top: 1px solid #d6e4ff;
    padding-top: 5px;
}

.font-white {
    color: #ffffff !important;
}

.font-weight-500 {
    font-weight: 500;
}

.progress-lg .ant-progress-outer {
    margin-right: calc(-2em - 32px) !important;
    padding-right: calc(2em + 32px) !important;
}

.progress-lg .ant-progress-text {
    font-size: 18px !important;
    color: #000 !important;
}

.progress-lg.ant-progress-status-success .ant-progress-text {
    color: #3fa672 !important;
}

.progress-lg .ant-progress-text .anticon {
    font-size: 18px;
}

.icon-btn {
    padding: 0px !important;
    border: 0px transparent !important;
    background: transparent !important;
}



.mt-50 {
    margin-top: 50px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-5 {
    margin-top: 5px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.divider-sm .ant-divider-inner-text {
    padding: 0px 8px !important;
}

.p-8 {
    padding: 8px !important;
}

.plr-8 {
    padding: 0px 8px !important;
}

.pl-2px {
    padding-left: 2px;
}

.pr-2px {
    padding-right: 2px;
}

.border-left-2px {
    border-left: 2px solid #d2d2d2;
}

.show-on-parent-hover {
    opacity: 0;
    pointer-events: none;
    transition: all .2s
}

.parent-hover:hover .show-on-parent-hover {
    opacity: 1;
    pointer-events: all;
}

.ant-popover-hidden .popover-btn {
    opacity: 0;
    pointer-events: none;
    transition: all .2s
}

.popover-no-padding .ant-popover-inner-content {
    padding: 0px !important;
}

.visible {
    opacity: 1;
    pointer-events: all;
}

.visibility-visible {
    visibility: visible;
}

.ant-popover:not(.ant-popover-hidden) .popover-btn {
    opacity: 1;
    pointer-events: all;
}

.avatar-very-large {
    width: 120px !important;
    height: 120px !important;
    line-height: 120px !important;
    border-radius: 100% !important;
}

.avatar-very-large2 {
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important;
    border-radius: 100% !important;
}


.avatar-very-large2>* {
    line-height: 80px !important;
    font-size: 40px;
}


.avatar-very-large>* {
    line-height: 120px !important;
    font-size: 60px;
}

.ant-btn-danger {
    background: #fff;
    color: #ff4d4f;
    border-color: #ff4d4f;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
    color: #fff !important;
    background-color: #ff4d4f !important;
    border-color: #ff4d4f !important;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #1098f7;
    background-color: #fff;
    border-color: #1098f7;
}

.ant-btn-primary {
    color: #fff;
    background-color: #1098f7;
    border-color: #1098f7;
}

.lg-btn {
    font-size: 20px !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
    line-height: 0px;
    font-weight: bold !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lg-btn.pl-0 {
    padding-left: 0px !important;
}

.lg-circle-btn {
    font-size: 20px !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    line-height: 0px;
    font-weight: bold !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.md-btn {
    font-size: 18px !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    line-height: 0px;
    font-weight: bold !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: #36cfc9;
    border-color: #36cfc9;
}

.ant-btn-primary:active {
    color: #fff;
    background-color: #08979c;
    border-color: #08979c;
}


.btn-yellow {
    color: #fff;
    background-color: #faa916;
    border-color: #faa916;
}

.btn-yellow:hover,
.btn-yellow:focus {
    color: #fff;
    background-color: #ffc53d;
    border-color: #ffc53d;
}

.btn-yellow:active {
    color: #fff;
    background-color: #d4b106;
    border-color: #d4b106;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #1098f7;
    background-color: #fff;
    border-color: #1098f7;
    border-style: solid;
}



.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
    color: #1098f7;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #1098f7 !important;
}

.ant-tabs-ink-bar {
    background-color: #1098f7;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #36cfc9;
}

.ant-select-selection:hover {
    border-color: #36cfc9;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #87e8de;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #1098f7;
}

.ant-radio-inner:after {
    background-color: #1098f7;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #1098f7;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #1098f7;
    border-color: #1098f7;
}

.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
    border-color: #1098f7;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    border-color: #36cfc9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
    box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
}

.ant-input:hover,
.ant-calendar-picker:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #1098f7;
}

.ant-input:focus,
.ant-input:active {
    border-color: #36cfc9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
    box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
}

.ant-calendar-today .ant-calendar-date {
    background-color: #fff;
    border-color: #1098f7;
    color: rgba(0, 0, 0, 0.65);
}

.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-selected-day .ant-calendar-date:hover {
    background-color: #1098f7;
}

.ant-calendar-date:hover {
    background: #eafffd;
}

.ant-calendar-date:active,
.ant-calendar-date:focus {
    background-color: #1098f7;
}


.ant-calendar-footer-btn a {
    color: #1098f7;
}

.ant-input-number:hover {
    border-color: #1098f7;
}

.ant-input-number:focus,
.ant-input-number:active {
    border-color: #36cfc9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
    box-shadow: 0 0 0 2px rgba(35, 144, 140, 0.2);
}

.ant-input-number-input-wrap:focus,
.ant-input-number-input-wrap:active {
    border-color: #36cfc9;
    outline-color: #36cfc9;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #1098f7;
}

.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #1098f7;
}

.ant-steps-item-finish>.ant-steps-item-tail:after {
    background-color: #1098f7;
}


.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active {
    background-color: #eafffd;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #1098f7;
    border-color: #1098f7;
}

.ant-radio-button-wrapper-checked {
    background: #fff;
    border-color: #1098f7 !important;
    color: #1098f7 !important;
    -webkit-box-shadow: -1px 0 0 0 #1098f7 !important;
    box-shadow: -1px 0 0 0 #1098f7 !important;
    z-index: 1;
}

.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-focused {
    color: #1098f7 !important;
    position: relative;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1098f7 !important;
    color: #fff !important;
}

.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active {
    background-color: #e6fffb !important;
}

.radio-group-grey .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: #e8e8e8 !important;
}

.radio-group-grey .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
    background: #fff !important;
}

.background-yellow {
    background: #faa916;
}

.angled::before {
    z-index: 0;
    content: '';
    background: #ffc53d;
    transform: rotate(-5deg);
    position: absolute;
    top: -150px;
    right: -100px;
    width: 150%;
    height: 400px;
}

​.pt-100 {
    padding-top: 100px !important;
}

.text-gold {
    color: #faa916 !important;
}

.main-header .ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
    color: #1098f7 !important;
    border-bottom: 0px solid transparent !important;
}


/**.btn-primary {
    background: #4d88ff;
    border-color: #4d88ff;
    font-weight: 600;
}

.btn-danger {
    background: #ff6262;
    border-color: #ff6262;
    font-weight: 600;
}

.btn-danger:hover {
    background: #ff3333;
    border-color: #ff3333;
}*/

.z-index-minus-1 {
    z-index: -1 !important;
}

.height-21 {
    height: 21px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.va-2 {
    vertical-align: 2px;
}

.va-minus-1 {
    vertical-align: -1px;
}

.padding-25 {
    padding: 25px !important;
}

.h-400 {
    height: 400px;
}

.min-h-400 {
    min-height: 400px;
}

.h-350 {
    height: 350px;
}

.h-300 {
    height: 300px;
}

.h-250 {
    height: 250px;
}

.w-auto {
    width: auto;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-150 {
    padding-top: 150px;
}

.pb-150 {
    padding-bottom: 150px;
}

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.notification-award .ant-notification-notice-message {
    margin-left: 74px !important;
}

.notification-award .ant-notification-notice-description {
    margin-left: 74px !important;
    width: 242px !important;
}

.notification-award .ant-notification-notice-content {
    min-height: 59px !important;
}

.background-dark-cyan {
    background: #08979c;
}

.text-orange {
    color: #faa916;
}


.grey-border-left {
    border-bottom: 0.5rem solid #d2d2d2;
}

.border-tier1 {
    border-bottom: 0.5rem solid #3fa672;
}

.border-tier2 {
    border-bottom: 0.5rem solid #faa916;
}

.border-tier3 {
    border-bottom: 0.5rem solid #ff4d4f;
}


.background-l-tier1 {
    background: #d9f7be;
}

.background-l-tier2 {
    background: #ffffb8;
}

.background-l-tier3 {
    background: #ffccc7;
}

.text-tier1 {
    color: #3fa672;
}

.text-tier2 {
    color: #fad905;
}

.text-tier3 {
    color: #f5222d;
}

.background-tier1 {
    background: #3fa672;
}

.background-tier2 {
    background: #fad905;
}

.background-tier3 {
    background: #ff4d4f;
}

.background-tier1-btn {
    background: #3fa672;
    color: #fff;
}

.background-tier1-btn:hover {
    background: #3fa672;
    color: #fff;
}

.background-tier2-btn {
    background: #fad905;
    color: #fff;
}

.background-tier2-btn:hover {
    background: #fad905;
    color: #fff;
}

.background-tier3-btn {
    background: #f5222d;
    color: #fff;
}

.background-tier3-btn:hover {
    background: #f5222d;
    color: #fff;
}

.pb-100 {
    padding-bottom: 100px;
}

.angled-grey-after {
    z-index: 0;
    background: #f1f1f1;
    transform: rotate(-5deg);
    position: absolute;
    bottom: -150px;
    right: -100px;
    width: 150%;
    height: 400px;
}

​ .background-ant-grey {
    background: #f5f5f5;
}

.uppercase {
    text-transform: uppercase;
}

.angled-grey::before {
    z-index: 0;
    content: '';
    background: #f1f1f1;
    transform: rotate(-5deg);
    position: absolute;
    top: -100px;
    right: -100px;
    width: 150%;
    height: 400px;
}

​ .special-day {
    background: purple !important;
}

.shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.ant-shadow-more {
    -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
}

.btn-larger {
    font-size: 24px;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 32px !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #1098f7 !important;
}

.vx-arcs-group text {
    color: #fff;
    text-shadow: -1px -1px 0 #222, 1px -1px 0 #222, -1px 1px 0 #222, 1px 1px 0 #222;
}

.font-bold-important {
    font-weight: bold !important;
}

.font-normal {
    font-weight: normal !important;
}

.menu-item-a-link {
    color: inherit !important;
    transition: none !important;
}

.info-alert-bg {
    background: rgb(186, 224, 255) !important;
}

.font-orange {
    color: #ff4612 !important;
}

.mt-200 {
    margin-top: 200px;
}

.mt-225 {
    margin-top: 225px;
}

.mt-250 {
    margin-top: 250px;
}

.mt-275 {
    margin-top: 275px;
}

.mt-300 {
    margin-top: 300px;
}

.mt-minus-25 {
    margin-top: -25px;
}

.min-h-520 {
    min-height: 520px;
}

.angled-cyan:before {
    z-index: 0;
    content: '';
    background: #1098f7;
    transform: rotate(-5deg);
    position: absolute;
    top: -130px;
    right: -100px;
    width: 150%;
    height: 400px;
}

.screen-overlay-modal {
    width: unset !important;
    padding-left: 48px;
    padding-right: 48px;
    animation-duration: 0s !important;
    transition-duration: 0s !important;
}

.screen-overlay-modal .ant-modal-content {
    width: calc(100vw - 112px);
}

.screen-overlay-modal .ant-modal-body {
    padding: 0px !important;
}


.home-overlay-outer {
    position: absolute;
    top: 32px;
    left: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 32px);
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.home-overlay-inner {
    position: absolute;
    top: 40px;
    left: 40px;
    width: calc(100% - 80px);
    /** height: calc(100% - 80px); */
}



.ant-menu-horizontal>.ant-menu-item>a:hover,
.ant-menu-horizontal>.ant-menu-submenu>a:hover {
    color: #1098f7;
}

.ant-menu-horizontal>.ant-menu-item>a,
.ant-menu-horizontal>.ant-menu-submenu>a {
    color: #000;
}

.mb-60 {
    margin-bottom: 60px;
}

.ptb-100 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.home-background-grey {
    background: #f1f1f1 !important;
}

.large-form-padding {
    padding: 38px 75px !important;
}

.home-btn {
    background: #36cfc9;
    box-shadow: 0 10px 21px rgba(0, 0, 0, 0.15);
}

.multiple-select-h-unset ul li {
    height: unset !important;
}

.form-labels-left .ant-form-item-label>label {
    text-align: left !important;
    width: 100% !important;
    white-space: normal;
    line-height: 1.3em;
}

.form-labels-float-left .ant-form-item-label>label {
    float: left;
    text-align: left !important;
    width: 100% !important;
    white-space: normal;
    line-height: 1.3em;
}

.menu-cyan-active {
    background: #1098f7;
    color: #fff;
}

.underNav {
    margin-top: 55px;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.pt-5px {
    padding-top: 5px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-200 {
    padding-top: 200px;
}

.mainContainer {
    margin-top: 70px;
}

.font-500 {
    font-weight: 500;
}

.menu-padding {
    padding: 12px 18px !important;
}

.mainContainerSecondary {
    margin-top: 142px;
}

.ant-message-custom-content.ant-message-info .anticon.anticon-info-circle {
    display: none;
}

.secondaryNav {
    width: 100%;
    background: #fff;
    height: 60px;
    position: relative;
}

.user-name {
    font-size: 18px;
    font-weight: bold;
    margin-left: 135px;
    vertical-align: -20px;
}

.ant-btn-outlined {
    background: #fff;
    color: #1098f7;
    border: 2px solid #1098f7 !important;
}

.ant-btn-outlined:hover {
    background: #1098f7 !important;
    border-color: #1098f7;
    color: #fff !important;
}

.ant-btn-outlined-sm {
    background: #fff;
    color: #1098f7;
    border: 1px solid #1098f7 !important;
}

.ant-btn-outlined-sm:hover {
    background: #1098f7 !important;
    border-color: #1098f7;
    color: #fff !important;
}

.mpb-8 {
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.m-lr-minus-16 {
    margin-left: -16px;
    margin-right: -16px;
}

.pa-5 {
    padding: 5px !important;
}

.event-card .btn-absolute-tl-text {
    position: absolute;
    top: 0px;
    left: 0px;
}

.event-card:active .btn-absolute-tl-text {
    position: absolute;
    top: 0px;
    left: 0px;
}

.rbc-resizer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    overflow: hidden;
    line-height: 8px;
    font-size: 11px;
    font-family: monospace;
    text-align: center;
    cursor: s-resize;
    /*user-drag: none;*/
}

.rbc-resizer::after {
    content: '=';
}

.event-card:focus .btn-absolute-tl-text {
    position: absolute;
    top: 0px;
    left: 0px;
}

.event-card .ant-card-head {
    padding: 3px 5px !important;
    min-height: 26px !important;
    background: inherit !important;
    color: #fff !important;
    /**border-bottom: 1px solid #2f54eb;*/
    border-bottom: transparent;
    border-radius: 0.333rem 0.333rem 0 0 !important;
}

.event-card .ant-card-body {
    padding: 8px !important;
    padding-top: 0px !important;
}

.event-card .ant-card-head-wrapper .ant-card-head-title,
.event-card .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    color: #fff !important;
}

.menu-grey {
    background: #f5f5f5 !important;
}

.menu-grey .ant-menu-submenu>.ant-menu {
    background: #f5f5f5 !important;
}

.ant-layout {
    background: #f8f8f8 !important;
}


.ant-empty-description {
    font-size: 20px;
    margin-top: 8px;
}

.font-size-anim {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    0% {
        font-size: 24px !important;
    }

    50% {
        font-size: 30px !important;
    }

    100% {
        font-size: 24px !important;
    }
}

.border-dark {
    border: 1px solid #333;
}

.border-left-dark {
    border-left: 1px solid #333;
}

.border-right-dark {
    border-right: 1px solid #333;
}

.border-top-dark {
    border-top: 1px solid #333;
}

.border-ltr-dark {
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    border-top: 1px solid #333;
}

.border-bottom-dark {
    border-bottom: 1px solid #333;
}

.border-right-light {
    border-right: 1px solid #e8e8e8;
}



.btn-vl {
    font-size: 20px;
    height: 60px !important;
    padding: 0px 32px !important;
}

.btn-cl {
    font-size: 20px;
    height: 42px !important;
    padding: 0px 32px !important;
}

.mt-5px {
    margin-top: 5px;
}

.font-28 {
    font-size: 28px;
}

.mt-9px {
    margin-top: 9px;
}

.left-margin {
    margin-left: 173px !important;
}

.sub-menu-width {
    max-width: 1024px;
}

.sub-menu-width-larger {
    max-width: 1200px;
}

.sub-menu-width-vlg {
    max-width: 1280px;
}

.background-color-light-cyan {
    background: #e6fffb !important;
}

.sub-menu-width-lg {
    max-width: 1030px;
}

.h-initial {
    height: auto !important;
}

.no-border-bottom {
    border-bottom: none !important;
}

.force-all-inline * {
    display: inline !important;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lh-initial {
    line-height: initial !important;
}

.bordered-table {
    width: 100% !important;
    border-collapse: collapse !important;
    border: 1px solid #a8a8a8 !important;
}

.bordered-table tr {
    border-bottom: 1px solid #a8a8a8 !important;
}

.bordered-table thead tr {
    border-bottom: 1px solid #a8a8a8 !important;
}

.bordered-table thead th {
    padding: 8px !important;
    border-bottom: none !important;
    border-right: 1px solid #a8a8a8 !important;
    font-size: 20px;
}

.bordered-table td {
    padding: 8px !important;
    border-right: 1px solid #a8a8a8 !important;
    font-size: 20px;
    vertical-align: top !important;
}

.lh-14 {
    line-height: 14px;
}

.va-minus-14 {
    vertical-align: -14px;
}

.border-top-dashed {
    border-top: 1px solid #d2d2d2;
}

.h-60px {
    height: 60px !important;
}

.lh-60px {
    line-height: 60px !important;
}

.tabs-w-100 .ant-tabs-bar {
    width: 100% !important;
}

.menu-large .ant-menu-submenu-title {
    height: 60px !important;
    line-height: 60px !important;
}

.rbc-time-header>.rbc-row:first-child {
    border-bottom: 0px transparent !important;
}

.rbc-header,
.rbc-label.rbc-header-gutter {
    padding: 12px !important;
    font-size: 14px !important;
    background: #fafafa !important;
}

.rbc-event-label {
    display: none !important;
}

.rbc-event {
    padding: 0px !important;
    background: transparent !important;
    border: 0px transparent !important;
}

.rbc-addons-dnd-over {
    background: #40a9ff !important;
}

/**disable all day row*/
.rbc-toolbar,
.rbc-time-header>.rbc-row:nth-of-type(2) {
    display: none !important;
}



.rbc-addons-dnd .rbc-row-content {
    pointer-events: none;
}

.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event {
    pointer-events: all;
}

.rbc-addons-dnd .rbc-events-container {
    pointer-events: none;
}

.rbc-addons-dnd .rbc-event {
    transition: opacity 150ms;
    pointer-events: all;
}

.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
    pointer-events: none;
    opacity: .50;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor {
    width: 100%;
    height: 10px;
    text-align: center;
    margin-left: -5px;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:first-child {
    position: absolute;
    top: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:last-child {
    position: absolute;
    bottom: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor .rbc-addons-dnd-resize-icon {
    display: none;
    border-top: 3px double;
    margin: 0 auto;
    width: 10px;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:hover {
    cursor: ns-resize !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-anchor:hover .rbc-addons-dnd-resize-icon {
    display: block;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event {
    position: relative;
    user-drag: none;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor {
    width: 20px;
    height: 20px;
    top: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:hover {
    cursor: ew-resize;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child {
    position: absolute;
    left: -5px;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child {
    position: absolute;
    right: -5px;
}

.rbc-day-slot .rbc-events-container {
    right: 0px !important;
}

.rbc-addons-dnd-resizable {
    width: 100%;
    height: 100%;
}

.block {
    display: block;
}

.text-yellow {
    color: #ffc53d;
}

.transparent-btn {
    border: transparent !important;
    background: transparent !important;
}

.transparent-btn:hover {
    border: transparent;
    background: transparent;
}

.transparent-btn-only {
    border: transparent !important;
    background: transparent !important;
}

.transparent-btn-only:hover {
    border: transparent;
    background: transparent;
}

.vertical-align-middle {
    vertical-align: middle !important;
}

.ml-minus40 {
    margin-left: -40px;
}

.header {
    background: #fafafa;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
}

.mts-3 {
    margin-top: 3px;
}

.fixed-form-size {
    min-width: 480px;
    max-width: 480px;
}

.fixed-form-size-med {
    min-width: 900px;
    max-width: 900px;
}

.school-banner {
    width: 100%;
    height: 320px;
    background: #8c8c8c;
}

.font-400 {
    font-weight: 400;
}

.mt-15 {
    margin-top: 15px;
}


.mr-5 {
    margin-right: 5px !important;
}

.mr-15 {
    margin-right: 15px;
}

.mr-45 {
    margin-right: 45px;
}

.pwb {
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.border-t {
    padding-top: 15px;
    border-top: 1px solid #ddd;
}

.bg-faded {
    background-color: white;
    border-bottom: 1px solid #ddd;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-10-5 {
    margin-bottom: 10.5px !important;
}

.generic-container {
    text-align: left;
}

.s-b {
    background: #fff;
    border: 1px solid #ddd;
}

.side-menu {
    width: 300px;
    float: left;
}


.side-menu a {
    color: #464a4c;
}

.side-menu a:not(.active):not(.primary-active):hover {
    color: #464a4c;
    background-color: #f7f7f9;
}

.primary-active {
    background: #01447e !important;
    border-color: #01447e !important;
}

.tertiary-active {
    background: #cce0ff !important;
    border-color: #cce0ff !important;
}


.padding-left-30 {
    padding-left: 30px;
}

.padding-right-30 {
    padding-right: 30px;
}

.padding-left-60 {
    padding-left: 60px;
}

.padding-left-90 {
    padding-left: 90px;
}

.padding-left-120 {
    padding-left: 120px;
}

.btn-group-btn {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    font-size: 18px;
}

.poll-textarea {
    height: 125px;
}

.poll-header2 {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 8px;
}

.inline-block {
    display: inline-block;
}

.invisible {
    visibility: hidden;
}

.gc {
    background: #ffffff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.profile-img-sm {
    width: 64px;
    height: auto;
}


.sideMenu-content {
    height: 500px;
    overflow-y: scroll;
    padding-top: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.main-content {
    margin-left: 300px;
    width: calc(100% - 300px);
}

.sideMenu ul {
    padding: 0px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.no-padding {
    padding: 0px !important;
}

.p-24 {
    padding: 24px;
}

.font-15 {
    font-size: 15px !important;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px !important;
}

.mr-24 {
    margin-right: 24px;
}

.w-300 {
    width: 300px;
}

.w-500 {
    width: 500px;
}

.w-520 {
    width: 520px;
}

.w-527 {
    width: 527px;
}

.w-548 {
    width: 548px;
}

.w-550 {
    width: 550px;
}

.w-600 {
    width: 600px;
}

.w-730 {
    width: 730px;
}

.w-850 {
    width: 850px;
}

.pt-70 {
    padding-top: 70px;
}

.p-nb {
    padding: 15px;
    padding-bottom: 0px;
}

.sideMenu li {
    list-style: none;
}

.mini-toolbar {
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    position: fixed;
    top: 55px;
    text-align: left;
}

.margb-10 {
    margin-bottom: 10px;
}

.menuHeader {
    padding: 0px 15px;
    padding-bottom: 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #0052cc;
}

.color-b {
    color: rgba(0, 0, 0, 0.65);
}

.color-b:hover {
    color: rgba(0, 0, 0, 0.65);
}

.standard-link {
    display: inline-block;
    color: #333;
    text-decoration: none;
    padding: 5px 15px 5px 15px;
}

.mt-1px {
    margin-top: 1px;
}

.standard-link:hover {
    background: #f1f1f1;
    color: #333;
    text-decoration: none;
}


.wmi-circle {
    border-radius: 50%;
    background: #1890ff;
}

.psi-circle {
    border-radius: 50%;
    background: #722ed1;
}

.fri-circle {
    border-radius: 50%;
    background: #eb2f96;
}

.vci-circle {
    border-radius: 50%;
    background: #3fa672;
}


.wmi-legend {
    vertical-align: -3px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #1890ff;
}

.psi-legend {
    vertical-align: -3px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #722ed1;
}

.fri-legend {
    vertical-align: -3px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #eb2f96;
}

.vci-legend {
    vertical-align: -3px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #3fa672;
}

.wmi-text {
    color: #1890ff;
}

.psi-text {
    color: #722ed1;
}

.fri-text {
    color: #eb2f96;
}

.vci-text {
    color: #2f54eb;
}

.standard-number {
    font-weight: bold;
    margin-right: 15px;
}

.standard-title {
    font-weight: bold;
}

.stat-text {
    color: rgba(0, 0, 0, 0.45);
}

.standard-description {
    margin-bottom: 0px;
}

.plr-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.pb-8 {
    padding-bottom: 8px;
}

.mtb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mr-12 {
    margin-right: 12px !important;
}

.pl-24 {
    padding-left: 24px;
}

.pr-24 {
    padding-right: 24px;
}

.p-24 {
    padding: 24px;
}

.subHeader {
    padding: 15px;
    font-size: 17px;
    background: #ccc;
    text-transform: uppercase;
    font-weight: bold;
    color: #484848;
}

.subHeader-sel {
    margin: 0px;
    padding: 15px 0px !important;
    border-bottom: 1px solid #f1f1f1;
}

.nav-text {
    font-size: 18px;
    font-weight: bold;
}

.mrl-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.white-space-prewrap {
    white-space: pre-wrap;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.standards-btn {
    width: 285px;
    display: inline-block;
}

.standards-info {
    display: inline-block;
}

.student-select {
    float: right;
    width: 300px;
    display: inline-block;
}

.student-progress .ant-progress-inner {
    width: 54px !important;
    height: 54px !important;
}

.ColContainer {
    width: 1190px;
}

.ant-radio-group label {
    padding: 0px 15px !important;
}

.ant-radio-group label.pl-0 {
    padding-left: 0px !important;
}

.ant-radio-group label.p-2 {
    padding: 16px !important;
}

.assessment-radio .ant-radio {
    width: 16px;
    height: 16px;
    margin-top: 6px;
}

.g-col {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    text-align: left;
}


.border {
    border: 1px solid #d2d2d2;
}

.standard-border .ant-tabs-bar {
    border: 1px solid #d2d2d2;
}

.bt-0 {
    border-top: 0px transparent !important;
}

.bb-0 {
    border-bottom: 0px transparent !important;
}

.bl-0 {
    border-left: 0px transparent !important;
}

.br-0 {
    border-right: 0px transparent !important;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.g-col-empty {
    text-align: left;
}

.sn {
    vertical-align: -1px;
}

.circle-img {
    border-radius: 50%;
}

.navSecondary-btn {
    margin-top: 10px;
    font-size: 18px;
    float: right;
}

.p15-nm {
    padding: 15px;
    margin: 0px;
}

.font-bold {
    font-weight: bold;
}

.iep-timestamp {
    float: right;
    font-weight: bold;
    color: #777;
    margin-top: 4px;
}



.iep-info {
    margin-left: 53px;
    color: #464646;
    text-decoration: none;
}

.iep-info:hover {
    text-decoration: none;
}

.position-fixed {
    position: fixed;
}

.mt-10 {
    margin-top: 10px;
}

.pill-vci {
    background: #4d88ff;
}

.pill-fri {
    background: #b580ff;
}

.pill-psi {
    background: #39ac73;
}

.pill-wmi {
    background: #ff9933;
}

.pill-vspi {
    background: #ff6666;
}

.pill-default {
    background: #b3ccff;
    color: #464646;
    font-size: 13px;
}

.ml-5p {
    margin-left: 5px;
}

.ml-8p {
    margin-left: 8px;
}

.marg-b7 {
    margin-bottom: 7px;
}

.mb-15 {
    margin-bottom: 15px;
}

.progress-light-green .ant-progress-bg {
    background: #3fa672 !important;
}

.progress-light-yellow .ant-progress-bg {
    background: #faa916 !important;
}

.progress-light-red .ant-progress-bg {
    background: #f5222d !important;
}

.arithmetic-operation {
    text-align: center;
}

.numerator,
.operator,
.denominator {
    display: inline-block;
}

.line {
    height: 2px;
    background-color: black;
    width: 100%;
}

.line-height-normal-important {
    line-height: normal !important;
}

.iframe-aspect-ratio {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
}

.iframe-aspect-ratio::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.iframe-aspect-ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.iframe-aspect-ratio-43 {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
}

.iframe-aspect-ratio-43::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.iframe-aspect-ratio-43>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.button--main {
    font-family: 'PoppinsBold';
    letter-spacing: .02em;
    display: inline-block;
    padding: 1rem 1.6rem !important;
    border-radius: 30px !important;
    border-color: transparent !important;
    background-image: linear-gradient(to bottom right, #1098f7, #1098f7, #722ed1);
    line-height: 27px !important;
    font-style: normal !important;
    text-align: center !important;
    color: #fff !important;
    text-decoration: none;
    font-weight: bolder !important;

    font-family: 'PoppinsBold';
    letter-spacing: .02em;
    display: inline-block;
    padding: 1rem 1.2rem !important;
    padding-left: 1rem !important;
    border-radius: 30px !important;
    border-color: transparent !important;
    background-image: linear-gradient(to bottom right, #1098f7, #1098f7, #722ed1);
    line-height: 27px !important;
    font-style: normal !important;
    text-align: center !important;
    color: #fff !important;
    text-decoration: none;
    font-weight: bolder !important;
}

.button--main:hover {
    text-decoration: none !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-color: transparent !important;
    background-image: linear-gradient(to bottom right, #faa916, #FF5757, #FF5757);
}


.button--main:active {
    text-decoration: none !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-color: transparent !important;
    background-image: linear-gradient(to bottom right, #faa916, #FF5757, #FF5757);
}

.button--main:focus {
    text-decoration: none !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-color: transparent !important;
    background-image: linear-gradient(to bottom right, #faa916, #FF5757, #FF5757);
}

.button--secondary {
    text-decoration: none;
    font-family: 'PoppinsBold';
    letter-spacing: .02em;
    padding: 1rem 1.6rem !important;
    border-radius: 30px !important;
    line-height: 27px !important;
    font-style: normal !important;
    text-align: center !important;
    color: #1098f7 !important;
    font-weight: bolder !important;
    border-color: transparent !important;
    border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(140deg, #1098f7, #722ed1);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #ffffff inset;
}

.button--secondary:hover {
    text-decoration: none !important;
    box-shadow: none;
    color: white !important;
    border-color: transparent !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(140deg, #1098f7, #722ed1);
}

.button--secondary:focus {
    text-decoration: none !important;
    box-shadow: none;
    color: white !important;
    border-color: transparent !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(140deg, #1098f7, #722ed1);
}


.button--secondary:active {
    text-decoration: none !important;
    box-shadow: none;
    color: white !important;
    border-color: transparent !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(140deg, #1098f7, #722ed1);
}



.collapse-no-border .ant-collapse-item {
    border-bottom: 0px solid transparent !important;
}

.iep-progress-nav {
    height: 72px;
}

.iep-progress-header {
    vertical-align: -11px;
}

.background-none {
    background: transparent !important;
    border: transparent !important;
}

.background-fff {
    background: #fff;
}

.ant-tabs-bar {
    background: #fff;
}

.snm {
    font-size: 18px;
    vertical-align: -3px;
}

.wizardCardImg {
    background: grey;
    height: 100px;
}

.w-900 {
    width: 900px !important;
}

.w-1100 {
    width: 1100px !important;
    overflow-x: auto;
}

.blue-underline-hover:hover {
    text-decoration: underline #0050b3;
}

.text-blue-hover:hover {
    color: #0050b3 !important;
}


.btn-light {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:hover {
    background-color: #c4ccd4;
    border-color: #b5bfc9;
}

.standard-btn {
    border-radius: 0.333rem;
    background: white;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 15px;
}

.standard-btn:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.standard-btn-selected {
    border: 1px solid #4d88ff;
}

.standard-header {
    font-weight: bold;
    font-size: 18px;
}

.shadow-hover:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.iep-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.iep-footer {
    border: 1px solid #e8e8e8;
    border-top: transparent;
    background: #fafafa;
    padding: 7px 15px;
    text-align: right;
    font-weight: bold;
}

.bottom-no-radius {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.padding-15 {
    padding: 15px;
}

.iep-choice-content-max-height {
    max-height: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 15px;
}

.border-radius-4 {
    border-radius: 0.333rem !important;
}

.border-none {
    border: none !important;
}

.lh-1-5em {
    line-height: 1.5em !important;
}

.lh-1-7em {
    line-height: 1.7em !important;
}

.of-hidden {
    overflow: hidden;
}

.mb-15 {
    margin-bottom: 15px !important
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.normal-line-height {
    line-height: 24px;
}

.ant-spin-dot i {
    background-color: #1098f7 !important;
}

.cyan-spin .ant-spin-dot i {
    background-color: #1098f7 !important;
}

.fff-spin .ant-spin-dot i {
    background-color: #fff !important;
}

.ant-spin-vlg .ant-spin-dot {
    height: 70px;
    width: 70px;
}

.ant-spin-vlg .ant-spin-dot i {
    height: 30px;
    width: 30px;
}

.bold-tabs .ant-tabs-tab {
    font-weight: bold !important;
}

.home-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -150px;
    width: 300px;
    height: 200px;
}

.new-shadow {
    box-shadow: 0 0 12px 2px #e0e0e0;
}

.tab-btn {
    font-size: 1.33rem;
    background: transparent;
    border: none;
    font-family: inherit;
    font-weight: bold;
    line-height: 1.1;
    color: inherit;
}

.tab-btn:focus {
    border: none;
    box-shadow: initial;
}

.tab-btn:hover {
    cursor: pointer;
}

.tab-highlight {
    color: #0275d8;
}

.no-text-decoration {
    text-decoration: none;
}

.no-text-decoration:focus {
    text-decoration: none;
}

.profileSummaryAlign {
    vertical-align: -6px;
}

.iepTable tr {
    padding: 0px !important;
    height: 100%;
}

.iepTable td {
    padding: 0px;
    height: 100%;
}

.iepTable td .row-link {
    padding: 12px;
    height: 100%;
}

.modal-func-triggered .ant-confirm-btns {
    display: none;
}

.float-none {
    float: none;
}

.text-align-right {
    text-align: right;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-align-none {
    text-align: none !important;
}

.modal-no-padding .ant-modal-body {
    padding: 0px !important;
}

.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}

.grid path {
    stroke-width: 0;
}


.table-checkbox-td {
    padding: 15px;
    padding-right: 60px !important;
    padding-left: 30px !important;
    text-align: left;
}

.last-td {
    text-align: right;
    padding-right: 30px !important;
}

.table-middle td {
    vertical-align: initial;
}

.table-checkbox {
    vertical-align: -5px;
}

.table-checkbox label {
    padding: 0px;
}

.va-instructional {
    vertical-align: -3px;
}

.selectedRow {
    background: #ffffb4;
}

.selectedRow:hover {
    background: #ffffb4 !important;
}

.text-align-left {
    text-align: left;
}

.layout-fixed {
    table-layout: fixed;
}

.mt-6 {
    margin-top: 6px;
}

.hidden {
    display: none;
}

.border-b {
    border-bottom: 1px solid #ddd;
}

.border-bottom {
    border-bottom: 1px solid #d2d2d2;
}

.border-top {
    border-top: 1px solid #d2d2d2;
}

.background-lg {
    background: #d2d2d2 !important;
}

.fade.in {
    opacity: 1;
}

.mb-90 {
    margin-bottom: 90px
}

.open>.dropdown-menu {
    display: block;
}

.dropdown-menu>li>a,
.dropdown-menu>li>div {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>div:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>div:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.dropdown-menu>li.active {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #4da6ff;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>div,
.dropdown-menu>.active>div:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #4d88ff;
}

.dropdown-menu>.active>div:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #3377ff;
}

.hover-pointer:hover {
    cursor: pointer;
}

.hover-pointer label:hover {
    cursor: pointer;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.m-lr-auto {
    margin-left: auto;
    margin-right: auto;
}

.w-500 {
    width: 500px;
}

.w-600 {
    width: 600px;
}

.input-number-picker {
    width: 200px;
    position: relative;
}

.input-number-picker:hover .input-number-btn {
    opacity: 1;
}

.input-number-up-btn {
    position: absolute;
    right: 1px;
    top: 1px;
    border-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-top: 0px transparent;
    border-right: 0px transparent;
}

.input-number-down-btn {
    position: absolute;
    right: 1px;
    bottom: 1px;
    border-radius: 0px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom: 0px transparent;
    border-right: 0px transparent;
    border-top: 0px transparent;
}

.background-mg {
    background: #d9d9d9;
}

.border-mg {
    border: 1px solid #bfbfbf;
}

.radius-0 {
    border-radius: 0px !important;
}

.br-1 {
    border-radius: 1px;
}

.br-2 {
    border-radius: 2px !important;
}

.br-4 {
    border-radius: 0.333rem !important;
}

.br-20 {
    border-radius: 15px !important;
}

.no-border-bottom-radius {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.no-border-top-radius {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.ant-btn {
    border-radius: 100px !important;
    font-weight: bold !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0s !important;
}

.chat-active-dot {
    background: #ff6675;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.collapse-vlg .ant-collapse-header {
    font-size: 20px !important;
}

.no-animation-transition {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
}

a[disabled] i {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
}

.br-4-important {
    border-radius: 0.333rem !important;
}

.br-tl-4 {
    border-top-left-radius: 0.333rem !important;
}

.br-tr-4 {
    border-top-right-radius: 0.333rem !important;
}

.br-bl-4 {
    border-bottom-left-radius: 0.333rem !important;
}

.br-br-4 {
    border-bottom-right-radius: 0.333rem !important;
}

.br-15 {
    border-radius: 15px !important;
}

.top-left-br-0 {
    border-top-left-radius: 0px !important;
}

.br-100 {
    border-radius: 100px;
}

.br-50 {
    border-radius: 50% !important;
}

.br-r-0 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.plans-table tr td:nth-child(4) {
    white-space: nowrap;
}

.overflow-auto {
    overflow: auto;
}

.ant-select-vlg-options .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 50px !important;
    line-height: 50px !important;
}

.teal-background {
    background: #1098f7;
    background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.25)), url(/public/teal-product-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.assessment-table td,
.assessment-table th {
    padding: 16px;
    overflow-wrap: break-word;
    border-bottom: 1px solid #e8e8e8;
    min-width: 220px;
}

.d3CustomTooltip {
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(100, 100, 111, 0.7);
    pointer-events: none !important;
}

/* Style the x-axis and y-axis ticks */
.d3-box-chart .x-axis .tick line,
.d3-box-chart .y-axis .tick line {
    stroke: #000;
    stroke-width: 1px;
}

.d3-box-chart .x-axis .tick text,
.d3-box-chart .y-axis .tick text {
    font-size: 16px;
    fill: #000;
}

/* Style the bars */
.d3-box-chart .box {
    fill: #36cfc9;
}

/*
.d3-truncated-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 190px;
    font-size: 16px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end
}
*/

.d3-truncated-label-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.d3-truncated-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 16px;
    display: inline-block;
}


.select-option-height-auto .ant-select-selection__choice {
    height: auto !important;
}

.alert-icon-mt i {
    margin-top: 5px;
}

.formkit-form[data-uid="df9dc6b162"] * {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    box-sizing: border-box;
}

.formkit-form[data-uid="df9dc6b162"] {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.formkit-form[data-uid="df9dc6b162"] legend {
    border: none;
    font-size: inherit;
    margin-bottom: 10px;
    padding: 0;
    position: relative;
    display: table;
}

.formkit-form[data-uid="df9dc6b162"] fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}

.formkit-form[data-uid="df9dc6b162"] body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

.formkit-form[data-uid="df9dc6b162"] h1,
.formkit-form[data-uid="df9dc6b162"] h2,
.formkit-form[data-uid="df9dc6b162"] h3,
.formkit-form[data-uid="df9dc6b162"] h4,
.formkit-form[data-uid="df9dc6b162"] h5,
.formkit-form[data-uid="df9dc6b162"] h6 {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.formkit-form[data-uid="df9dc6b162"] p {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.formkit-form[data-uid="df9dc6b162"][data-format="modal"] {
    display: none;
}

.formkit-form[data-uid="df9dc6b162"][data-format="slide in"] {
    display: none;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input,
.formkit-form[data-uid="df9dc6b162"] .formkit-select,
.formkit-form[data-uid="df9dc6b162"] .formkit-checkboxes {
    width: 100%;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-button,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit {
    border: 0;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 15px;
    overflow: hidden;
    padding: 0;
    position: relative;
    vertical-align: middle;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-button:hover,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit:hover,
.formkit-form[data-uid="df9dc6b162"] .formkit-button:focus,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit:focus {
    outline: none;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-button:hover>span,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit:hover>span,
.formkit-form[data-uid="df9dc6b162"] .formkit-button:focus>span,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit:focus>span {
    background-color: rgba(0, 0, 0, 0.1);
}

.formkit-form[data-uid="df9dc6b162"] .formkit-button>span,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit>span {
    display: block;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    padding: 12px 24px;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input {
    background: #ffffff;
    font-size: 15px;
    padding: 12px;
    border: 1px solid #e3e3e3;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    line-height: 1.4;
    margin: 0;
    -webkit-transition: border-color ease-out 300ms;
    transition: border-color ease-out 300ms;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input:focus {
    outline: none;
    border-color: #1677be;
    -webkit-transition: border-color ease 300ms;
    transition: border-color ease 300ms;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.8;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input::-moz-placeholder {
    color: inherit;
    opacity: 0.8;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input:-ms-input-placeholder {
    color: inherit;
    opacity: 0.8;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-input::placeholder {
    color: inherit;
    opacity: 0.8;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="dropdown"] {
    position: relative;
    display: inline-block;
    width: 100%;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="dropdown"]::before {
    content: "";
    top: calc(50% - 2.5px);
    right: 10px;
    position: absolute;
    pointer-events: none;
    border-color: #4f4f4f transparent transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    height: 0;
    width: 0;
    z-index: 999;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="dropdown"] select {
    height: auto;
    width: 100%;
    cursor: pointer;
    color: #333333;
    line-height: 1.4;
    margin-bottom: 0;
    padding: 0 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 15px;
    padding: 12px;
    padding-right: 25px;
    border: 1px solid #e3e3e3;
    background: #ffffff;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="dropdown"] select:focus {
    outline: none;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] {
    text-align: left;
    margin: 0;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] {
    margin-bottom: 10px;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] * {
    cursor: pointer;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"]:last-of-type {
    margin-bottom: 0;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] input[type="checkbox"] {
    display: none;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] input[type="checkbox"]+label::after {
    content: none;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] input[type="checkbox"]:checked+label::after {
    border-color: #ffffff;
    content: "";
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] input[type="checkbox"]:checked+label::before {
    background: #10bf7a;
    border-color: #10bf7a;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] label::before,
.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] label::after {
    position: absolute;
    content: "";
    display: inline-block;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] label::before {
    height: 16px;
    width: 16px;
    border: 1px solid #e3e3e3;
    background: #ffffff;
    left: 0px;
    top: 3px;
}

.formkit-form[data-uid="df9dc6b162"] [data-group="checkboxes"] [data-group="checkbox"] label::after {
    height: 4px;
    width: 8px;
    border-left: 2px solid #4d4d4d;
    border-bottom: 2px solid #4d4d4d;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 4px;
    top: 8px;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-alert {
    background: #f9fafb;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    list-style: none;
    margin: 25px auto;
    padding: 12px;
    text-align: center;
    width: 100%;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-alert:empty {
    display: none;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-alert-success {
    background: #d3fbeb;
    border-color: #10bf7a;
    color: #0c905c;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-alert-error {
    background: #fde8e2;
    border-color: #f2643b;
    color: #ea4110;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-spinner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 0px;
    width: 0px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 0px;
    overflow: hidden;
    text-align: center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-spinner>div {
    margin: auto;
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 0.3;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: formkit-bouncedelay-formkit-form-data-uid-df9dc6b162- 1.4s infinite ease-in-out both;
    animation: formkit-bouncedelay-formkit-form-data-uid-df9dc6b162- 1.4s infinite ease-in-out both;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-spinner>div:nth-child(1) {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-spinner>div:nth-child(2) {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-submit[data-active] .formkit-spinner {
    opacity: 1;
    height: 100%;
    width: 50px;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-submit[data-active] .formkit-spinner~span {
    opacity: 0;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-powered-by[data-active="false"] {
    opacity: 0.35;
}

@-webkit-keyframes formkit-bouncedelay-formkit-form-data-uid-df9dc6b162- {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes formkit-bouncedelay-formkit-form-data-uid-df9dc6b162- {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.formkit-form[data-uid="df9dc6b162"] {
    border: 1px solid #e3e3e3;
    max-width: 700px;
    position: relative;
    overflow: hidden;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    z-index: 1;
}

.formkit-form[data-uid="df9dc6b162"] [data-style="minimal"] {
    padding: 20px;
    width: 100%;
    z-index: 2;
    position: relative;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-header {
    margin: 0 0 27px 0;
    text-align: center;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-subheader {
    margin: 18px 0;
    text-align: center;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-guarantee {
    font-size: 13px;
    margin: 10px 0 15px 0;
    text-align: center;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-guarantee>p {
    margin: 0;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-powered-by {
    color: #7d7d7d;
    display: block;
    font-size: 12px;
    margin: 10px 0 0 0;
    text-align: center;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-fields {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 25px auto 0 auto;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-field {
    min-width: 220px;
}

.formkit-form[data-uid="df9dc6b162"] .formkit-field,
.formkit-form[data-uid="df9dc6b162"] .formkit-submit {
    margin: 0 0 15px 0;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
}

.formkit-form[data-uid="df9dc6b162"][min-width~="600"] [data-style="minimal"] {
    padding: 40px;
}

.formkit-form[data-uid="df9dc6b162"][min-width~="600"] .formkit-fields[data-stacked="false"] {
    margin-left: -5px;
    margin-right: -5px;
}

.formkit-form[data-uid="df9dc6b162"][min-width~="600"] .formkit-fields[data-stacked="false"] .formkit-field,
.formkit-form[data-uid="df9dc6b162"][min-width~="600"] .formkit-fields[data-stacked="false"] .formkit-submit {
    margin: 0 5px 15px 5px;
}

.formkit-form[data-uid="df9dc6b162"][min-width~="600"] .formkit-fields[data-stacked="false"] .formkit-field {
    -webkit-flex: 100 1 auto;
    -ms-flex: 100 1 auto;
    flex: 100 1 auto;
}

.formkit-form[data-uid="df9dc6b162"][min-width~="600"] .formkit-fields[data-stacked="false"] .formkit-submit {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.background-c11 {
    background: #fff1b8;
}

.angled-cyan-iep:before {
    z-index: 0;
    content: '';
    background: #ffc53d;
    transform: rotate(-5deg);
    position: absolute;
    top: 0px;
    right: -100px;
    width: 150%;
    height: 500px;
}

.btn-vs {
    height: 19px !important;
}

.br-100 .ant-select-selection {
    border-radius: 100px;
}



.pl-05 {
    padding-left: 4px !important;
}

.pr-05 {
    padding-right: 4px !important;
}

.input-number-btn {
    font-size: 9px;
    font-weight: bold;
    opacity: 0;
    -webkit-transition: opacity .24s linear .1s;
    transition: opacity .24s linear .1s;
    z-index: 2;
}

.input-number-btn:hover {
    background: #f1f1f1;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
}

.input-number-btn:focus {
    background: #f1f1f1;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
}

.number-input-hover {
    border-color: #40a9ff;
}

.number-input-focus {
    border-color: #40a9ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.width-90px {
    width: 90px;
}

.test-progress {
    width: 80px;
    height: 80px;
}

.test-progress .ant-progress-inner {
    width: 80px !important;
    height: 80px !important;
    font-size: 20px !important;
}

.height-35px {
    height: 35px;
}

.chart-tooltip {
    background: #fff !important;
}

.chart-tooltip-title {
    color: #333 !important;
}

.chart-tooltip-item {
    color: #333 !important;
}

.mw-109 {
    max-width: 109px;
}

.bkg-purple {
    background: #233142 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.text-of-ellipsis {
    text-overflow: ellipsis;
}

.block-label .ant-form-item-label {
    overflow: initial;
    display: block;
    white-space: normal;
    line-height: 22px;
    text-align: left;
    margin-bottom: 8px;
}

.block-label label {
    overflow: initial;
    white-space: normal;
    line-height: 22px;
    text-align: left;
}

.block-label-form .ant-form-item-label {
    overflow: initial;
    display: block;
    white-space: normal;
    line-height: 22px;
    text-align: left !important;
    margin-bottom: 8px;
}

.form-label-font-normal label {
    font-weight: normal !important;
}

.results-table td {
    padding: 24px;
    padding-bottom: 8px;
    padding-top: 8px;
}

.results-table-question-cell {
    min-width: 140px !important
}

.stats-table td,
.stats-table th {
    padding: 24px;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #e8e8e8;
}

.p-mb-0 p {
    margin-bottom: 0px !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

/*
dark blue:
#233142

blue:
#1098f7

light blue:
#b3e9ff

dark green:
#3fa672

green:
#59cd90

light green:
#ace6c3
dark gold:
#faa916

Gold:
#ffb537

light gold:
#fff0ba

Dark red:
#f37e7e
Light red:
#ffb1ad
dark green:
#ace6c6
loght green:
#dcf2e4
dark gold:
#ffd469
light gold:
#ffe491
*/

.image-box-mask {
    background-image: url(../../public/banneralpha2.png);
    height: auto;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.radio-group-display-block .ant-radio-wrapper {
    display: flex;
    align-items: center;
}

.no-margin-bottom-form-item .ant-form-item {
    margin-bottom: 0px !important;
}

.ant-form-item-required::before {
    font-size: 16px !important;
    display: none !important;
}

.button-row .ant-btn {
    font-size: 16px !important;
    color: #fff !important;
}

.up-hover {
    -webkit-transition: transform 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out;
    -o-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.up-hover:hover {
    transform: translateY(-2px)
}

.left-hover,
.left-hover-parent {
    -webkit-transition: transform 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out;
    -o-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.left-hover:hover,
.left-hover-parent:hover .left-hover-child {
    transform: translateX(-2px);
}

.right-hover,
.right-hover-parent {
    -webkit-transition: transform 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out;
    -o-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.right-hover:hover,
.right-hover-parent:hover .right-hover-child {
    transform: translateX(2px);
}

.disabled-overlay {
    background: silver;
    opacity: .5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.disabled-overlay:hover {
    cursor: not-allowed;
}

.cursor-text:hover {
    cursor: text !important;
}

.cursor-text:hover .ant-select-selection {
    cursor: text !important;
}

.shadow-hover:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1)
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.negative-modal-margin {
    margin: -24px;
}

.mt-6px {
    margin-top: 6px;
}

.card-container>.ant-tabs-card>.ant-tabs-content {
    height: 120px;
}

.card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
    background: #f1f1f1;
    padding: 16px;
}

.card-container>.ant-tabs-card>.ant-tabs-bar {
    border-color: #f1f1f1;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
    border-color: #f1f1f1;
    background: #f1f1f1;
}

.blue-tabs .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    font-weight: 500;
    background: #1890ff;
    border-radius: 2px;
    color: white;
    padding: 8px;
}

.blue-tabs .ant-tabs-nav .ant-tabs-tab {
    width: 57px;
    padding: 0px;
    text-align: center;
    padding: 8px;
}

.blue-tabs .ant-tabs-ink-bar {
    height: 0px;
    background-color: transparent;
}

.avatar-left .ant-avatar-string {
    left: calc(50% - 6px) !important;
}


.edviz-list-style-none ol {
    list-style: none !important;
}

.edviz-document {
    font-size: 20px !important;
}

.edviz-document h1 {
    font-size: 2em !important;
    font-weight: bold !important;
    margin-bottom: 0.5em !important;
    color: #000 !important;
}

.edviz-document h2 {
    font-size: 1.5em !important;
    font-weight: bold !important;
    margin-top: 24px !important;
    margin-bottom: 0.5em !important;
    color: #000 !important;
}

.edviz-document h3 {
    font-size: 1.17em !important;
    font-weight: bold !important;
    margin-top: 12px;
    margin-bottom: 0.5em !important;
    color: #000 !important;
}

.edviz-document table {
    width: 100% !important;
    border-collapse: collapse !important;
    border: 1px solid #a8a8a8 !important;
    margin-top: 16px !important;
}

.edviz-document table tr {
    border-bottom: 1px solid #a8a8a8 !important;
}

.edviz-document table thead tr {
    border-bottom: 1px solid #a8a8a8 !important;
}

.edviz-document table thead th {
    padding: 8px !important;
    border-bottom: none !important;
    border-right: 1px solid #a8a8a8 !important;
    font-size: 20px;
}

.edviz-document table td {
    padding: 8px !important;
    border-right: 1px solid #a8a8a8 !important;
    font-size: 20px;
    vertical-align: top !important;
}

.edviz-document hr {
    border: 1px solid #e8e8e8 !important;
}

.edviz-document ol {
    margin-bottom: 8px !important;
}

.edviz-document ol ol {
    padding-left: 16px !important;
}

.edviz-document blockquote {
    margin-bottom: 8px !important;
}

.edviz-document-small {
    font-size: 16px !important;
}

.edviz-document-small h1 {
    font-size: 24px !important;
}

.edviz-document-small h2 {
    margin-top: 16px !important;
    font-size: 20px !important;
    font-weight: bold !important;
}

.edviz-document-small h3 {
    font-size: 16px !important;
}


.edviz-document-v2 {
    font-size: 20px;
    color: #000;
}

.edviz-document-v2 p {
    font-size: 20px !important;
}

.edviz-document-v2 ul {
    font-size: 20px !important;
}

.edviz-document-v2 ol {
    font-size: 20px !important;
}

.edviz-document-v2 ol ol {
    padding-left: 16px !important;
}

.edviz-document-v2 h1 {
    font-size: 34px !important;
    font-weight: bold !important;
    margin-bottom: 16px !important;
    color: #000 !important;
}

.edviz-document-v2-heading-1 {
    font-size: 34px !important;
    font-weight: bold !important;
    margin-bottom: 17px !important;
    color: #000 !important;
}


.edviz-document-v2 h2 {
    font-size: 24px !important;
    font-weight: bold !important;
    margin-bottom: 16px !important;
    color: #000 !important;
}

.edviz-document-v2 h3 {
    font-size: 24px !important;
    font-weight: bold !important;
    margin-bottom: 12px !important;
    color: #000 !important;
}

.edviz-document-v2 h4 {
    font-size: 16px !important;
}

.edviz-document-v2 h5 {
    font-size: 14px !important;
}

.edviz-document-v2 h6 {
    font-size: 12px !important;
}

.edviz-document-v2 .heading1 {
    font-size: 2em !important;
    font-weight: bold !important;
    margin-bottom: 0.5em !important;
}

.edviz-document-v2 .heading2 {
    font-size: 1.5em !important;
    font-weight: bold !important;
    margin-bottom: 0.5em !important;
}

.edviz-document-v2 .heading3 {
    font-size: 1.17em !important;
    font-weight: bold !important;
    margin-bottom: 0.5em !important;
}

.background-darker-blue {
    background: #233142 !important;
}


.event-card {
    z-index: 1;
    text-align: left;
    color: #fff !important;
    background: #4775d1 !important;
    border-color: #ffffff !important;
    font-weight: 500;
}

.event-card:hover {
    background: #7094db !important;
}

.event-card:active {
    background: #7094db !important;
}

.event-card:focus {
    background: #7094db !important;
}

.paper-btn {
    background: transparent !important;
    border-color: transparent !important;
    color: #000 !important;
}

.paper-btn:hover {
    color: #333 !important;
}

.paper-btn:disabled {
    color: #a8a8a8 !important;
}

.font-cyan-hover:hover {
    color: #1098f7 !important;
}

.disabled-text {
    color: #a8a8a8 !important;
}

.rbc-event {
    overflow: visible !important;
}

.rbc-event:hover .rbc-addons-dnd-resizable .rbc-addons-dnd-resize-ns-anchor {
    opacity: 1;
}

.rbc-event-content {
    overflow: hidden;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
    top: -17px !important;
    z-index: 0;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
    bottom: -17px !important;
    z-index: 0;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
    position: absolute;
    left: 45% !important;
    margin-left: -10px !important;
    width: 20px !important;
    height: 20px !important;
    color: #fff;
    background: #4775d1;
    border-radius: 50% !important;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
    transition: all .2s
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    cursor: ns-resize;
    height: 20px !important;
    width: 20px !important;
    font-size: 13px !important;
    text-align: center !important;
    border-top: none !important;
    opacity: 1;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child .rbc-addons-dnd-resize-ns-icon:after {
    font-family: "anticon" !important;
    content: "\e61e";
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child .rbc-addons-dnd-resize-ns-icon:after {
    font-family: "anticon" !important;
    content: "\e61d";
}

.is-dragging .event-container-placed {
    pointer-events: none;
    opacity: .5;
}

.isCopyAndPasting .paste-drop-info {
    visibility: visible;
}

.pointer-events-none {
    pointer-events: none;
}

.cursor-disabled {
    cursor: not-allowed;
}

.cursor-not-allowed:hover {
    cursor: not-allowed !important;
}

.background-light-grey-blue {
    background: #e6f1f5 !important;
}

.background-lighter-blue {
    background: #e6f4ff !important;
}

.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

.opacity-50 {
    opacity: .5;
}

.opacity-1 {
    opacity: 1 !important;
}

.bg-fff-opacity-50 {
    background: rgba(255, 255, 255, 0.5);
}

/* Blue outline on focus */
.StripeElement--focus {
    border-color: #1098f7;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(19, 194, 194, .25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.locked {
    background: #ececec !important;
}

.locked:hover {
    background: #ececec !important;
    color: #222 !important;
}

.full-width-btn {
    height: 50px !important;
    line-height: 50px !important;
    text-align: center !important;
    font-size: 24px !important;
    width: 100% !important;
}

.locked-transparent {
    background: transparent;
}

.locked-bg {
    background: #ececec !important;
}

.visibility-animation {
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

.fullscreen-modal .ant-modal {
    width: calc(100% - 100px) !important;
    height: calc(100% - 100px);
    margin: 0;
    padding: 0;
    overflow: auto;
}

.fullscreen-modal .ant-modal-content {
    height: 100%;
}

.fullscreen-modal .ant-modal-body {
    height: calc(100% - 55px);
    /* Adjust as needed based on header/footer sizes */
    overflow: auto;
}

#Quarter-1,
#Quarter-2,
#Quarter-3,
#Quarter-4 {
    scroll-margin-top: 100px;
}

.multi-line-ellipsis:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    /* adjust this to your background color */
    padding-left: 5px;
    /* to push ellipsis a bit away from the last character */
}

.multi-line-ellipsis:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
    /* adjust this to your background color */
    z-index: 1;
    /* put this on top */
}

.text-blur::after {
    content: "";
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    height: 48px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, .05), rgba(255, 255, 255, .7));
    pointer-events: none;
}

.locked:hover .locked-bg {
    background: #1098f7 !important;
    color: #fff !important;
}

.locked-transparent:hover .locked-bg {
    background: #1098f7 !important;
    color: #fff !important;
}

.background-fff-important {
    background: #fff !important;
}



.locked-icon {
    color: #888;
}

/* Can't see what I type without this */
#card-number.form-control,
#card-cvc.form-control,
#card-exp.form-control {
    display: inline-block;
}

#card-errors {
    margin-top: 4px;
    color: #f5222d;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.inline-flex-important {
    display: inline-flex !important;
}

.block-important {
    display: block !important;
}

.inline-block-important {
    display: inline-block !important;
}

.custom-alert {
    background: #b3e9ff;
    border-top: 3px solid #1098f7;
}

.custom-grid line {
    shape-rendering: crispEdges;
}

.custom-grid path {
    stroke-width: 0;
}


.absolute-tl-important {
    position: absolute !important;
    top: 0;
    left: 0;
}

.p-lr-20-important {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-6-cols {
    flex: 1 0 16.6666667%;
}

.flex-v-center {
    align-items: center;
}

.flex-v-end {
    align-items: flex-end;
}

.flex-h-center {
    justify-content: center;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-right {
    align-self: flex-end;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.menu-no-bb {
    border-bottom: 0px transparent !important;
}

.time-gap {
    padding-bottom: 99px;
    border-bottom: 1px solid #d2d2d2;
}

.hover-grey:hover {
    background: #ccc;
}

.hover-light-grey:hover {
    background: #f1f1f1 !important;
}

.ant-avatar-string {
    display: inline-flex !important;
    position: relative !important;
    left: 0px !important;
    transform: none !important;
}

.ant-avatar-vs {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    border-radius: 12px;
}

.float-right-i {
    float: right !important;
}

.tabs-no-overflow-hidden .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content {
    overflow: visible !important;
}

.tabs-no-overflow-hidden.ant-tabs {
    overflow: visible !important;
}

.ant-layout-header {
    height: initial !important;
    line-height: initial !important;
    background: #fff !important;
}

.background-transparent {
    background: transparent !important;
}

.title-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.title-tooltip:hover::after {
    content: attr(data-title);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
}

.select-custom-vlg .ant-select-selection {
    height: 72px !important;
    padding: 16px 9px !important;
    font-size: 24px !important;
}

.background-dark-purple {
    background: #22075e !important;
}

.background-dark-blue {
    background: #314969 !important;
}

.white-space-normal {
    white-space: normal !important;
}

.h-0 {
    height: 0px !important;
}

.h-69px {
    height: 69px;
}

.h-67px {
    height: 67px;
}

.h-80 {
    height: 80px;
}

.ant-tabs.sticky-left.sticky-left-more .ant-tabs-top-bar {
    position: sticky;
    left: 286px;
    z-index: 2;
    /* Keep it on top */
    background: white;
    display: table !important;
}

.ant-tabs.sticky-left.sticky-left-more .ant-tabs-nav {
    padding-right: 16px !important;
}

.sticky-left {
    position: sticky;
    left: 269px;
    z-index: 2;
    /* Keep it on top */
    background: transparent;
}

.sticky-left-more {
    left: 286px !important;
}

.sticky-right {
    position: sticky;
    right: 0px;
    z-index: 2;
    padding-right: 32px;
    /* Keep it on top */
    background: transparent;
}

.sticky-left-shadow::before,
.sticky-left-shadow::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    /* width of the shadow */
    background: linear-gradient(to right, rgba(100, 100, 111, 0.15), transparent);
    z-index: 10;
    /* Adjust this value if needed */
}

.sticky-right-shadow::after,
.sticky-right-shadow::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    /* width of the shadow */
    background: linear-gradient(to left, rgba(100, 100, 111, 0.15), transparent);
    z-index: 10;
    /* Adjust this value if needed */
}


/** Video CSS */
/*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit 
  the CSS below. Click "details" 
  below to add comments or questions.
  This file uses some SCSS. Learn more  
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/
.video-js {
    /* The base font size controls the size of everything, not just text.
       All dimensions use em-based sizes so that the scale along with the font size.
       Try increasing it to 15px and see what happens. */
    font-size: 10px;
    /* The main font color changes the ICON COLORS as well as the text */
    color: #fff;
}

/* The "Big Play Button" is the play button that shows before the video plays.
     To center it set the align values to center and middle. The typical location
     of the button is the center, but there is trend towards moving it to a corner
     where it gets out of the way of valuable content in the poster image.*/
.vjs-default-skin .vjs-big-play-button {
    /* The font size is what makes the big play button...big. 
       All width/height values use ems, which are a multiple of the font size.
       If the .video-js font-size is 10px, then 3em equals 30px.*/
    font-size: 3em;
    /* We're using SCSS vars here because the values are used in multiple places.
       Now that font size is set, the following em values will be a multiple of the
       new font size. If the font-size is 3em (30px), then setting any of
       the following values to 3em would equal 30px. 3 * font-size. */
    /* 1.5em = 45px default */
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    /* 0.06666em = 2px default */
    border: 0.06666em solid #fff;
    /* 0.3em = 9px default */
    border-radius: 0.3em;
    /* Align center */
    left: 50%;
    top: 50%;
    margin-left: -1.5em;
    margin-top: -0.75em;
}

.video-js .vjs-big-play-button {
    left: 50%;
    top: 50%;
    margin-left: -1.5em;
    margin-top: -0.75em;
}

/* The default color of control backgrounds is mostly black but with a little
     bit of blue so it can still be seen on all-black video frames, which are common. */
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: transparent;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba(0, 0, 0, 0.7);
}

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
    background-color: rgba(84, 84, 84, 0);
    background-color: rgba(84, 84, 84, 0.5);
}

/* The slider bar color is used for the progress bar and the volume bar
     (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
    background: #fff;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
    /* For IE8 we'll lighten the color */
    background: rgba(148, 148, 148, 0);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba(84, 84, 84, 0.5);
}

/* The load progress bar also has internal divs that represent
     smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
    /* For IE8 we'll lighten the color */
    background: rgba(212, 212, 212, 0);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba(84, 84, 84, 0.75);
}

.calendar-header {
    background-color: #fff;
    color: #1098f7;
    font-size: 20px;
    font-weight: 700;
}

.page-break-inside-avoid-not-important {
    page-break-inside: avoid;
}

.page-break-inside-avoid {
    page-break-inside: avoid !important;
}

.break-inside-avoid {
    break-inside: avoid !important;
}

.font-tier-1 {
    color: #3fa672 !important;
}

.background-tier-1 {
    background-color: #3fa672 !important;
}

.file-upload-btn input[type="file"] {
    display: none;
}

.print-show {
    display: none !important;
}

.question-container {
    min-width: 700px !important;
    width: 700px !important;
    max-width: 700px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #fff !important;
}

.question-container-with-padding-4 {
    min-width: 764px !important;
    width: 764px !important;
    max-width: 764px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #fff !important;
}

.background-fully-transparent {
    background: transparent !important;
}

.background-light-grey-important {
    background: #f8f8f8 !important;
}


@page {
    size: auto;
    margin-left: 7mm !important;
    margin-right: 7mm !important;
    margin-top: 7mm !important;
    margin-bottom: 7mm !important;
    overflow: visible !important;
}

.chart-container h3 {
    color: #000;
    text-align: center;
}

.axis line,
.axis path {
    stroke: #000 !important;
}

.axis text {
    fill: #000 !important;
}

.inline-component {
    display: inline-block;
    vertical-align: middle;
    /* Adjust as necessary for your layout */
    white-space: nowrap;
}

.inline-svg {
    display: inline-block;
    vertical-align: middle;
    /* Adjust as necessary for your layout */
    white-space: nowrap;
}

.inline-component {
    display: inline-flex;
    margin: 4px;
    /* Adjust the margin size as needed */
}

.answer {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.correct {
    background-color: #e0ffe0;
}

.incorrect {
    background-color: #ffe0e0;
}

.answer-id {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.answer-choice {
    margin-bottom: 10px;
}

.answer-choice-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.choice-label {
    font-weight: bold;
    margin-right: 5px;
}

.answer-reason {
    margin-top: 10px;
    font-style: italic;
}

.numberline-container .tick-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    /* Increased by 50% */
    text-align: center;
    width: 100%;
    height: 100%;
}

.numberline-container .tick-label.negative {
    margin-left: -0.5em;
    /* Adjust the negative sign position */
}

.numberline-container .tick-label.positive {
    margin-left: 0.0em;
    /* Adjust the positive sign position */
}

@media print {
    * {
        color: #000 !important;
    }

    .print-background-fff {
        background: #fff !important;
    }

    .border-success {
        border-color: #000 !important;
    }

    .bg-light-green {
        background-color: #f8f8f8 !important;
    }

    .border-success {
        border-color: #000 !important;
    }

    .bg-light-green {
        background-color: #f8f8f8 !important;
    }

    .border-error {
        border-color: #000 !important;
    }

    .bg-light-red {
        background-color: #f8f8f8 !important;
    }

    .ant-avatar {
        background-color: #a8a8a8 !important;
    }

    .print-remove-padding {
        padding: 0 !important;
    }

    .print-remove-margin {
        margin: 0 !important;
    }

    .print-remove-border {
        border: 0 !important;
    }

    .print-remove-shadow {
        box-shadow: none !important;
    }

    .w-100-print {
        width: 100% !important;
    }

    .print-show {
        display: block !important;
    }

    .print-hide {
        display: none !important;
    }

    .print-text-left {
        text-align: left !important;
    }

    .print-mt-0 {
        margin-top: 0px !important;
    }

    .print-font-14 {
        font-size: 14px !important;
    }

    .print-font-16 {
        font-size: 16px !important;
    }

    .print-font-18 {
        font-size: 18px !important;
    }

    .print-font-20 {
        font-size: 20px !important;
    }

    .print-font-24 {
        font-size: 24px !important;
    }

    .print-font-30 {
        font-size: 30px !important;
    }

    .print-p1 {
        padding: 8px !important;
    }

    .print-p2 {
        padding: 16px !important;
    }

    .print-p3 {
        padding: 24px !important;
    }

    .print-border {
        border: 1px solid #f1f1f1 !important;
    }

    .print-br-15 {
        border-radius: 15px !important;
    }

    .print-mb-0 {
        margin-bottom: 0 !important;
    }

    .print-mb-05 {
        margin-bottom: 4px !important;
    }

    .print-mb-1 {
        margin-bottom: 8px !important;
    }

    .print-mb-15 {
        margin-bottom: 12px !important;
    }

    .print-mb-3 {
        margin-bottom: 24px !important;
    }

    .print-mt-2 {
        margin-top: 16px !important;
    }

    .print-display-block {
        display: block !important;
    }

    .border-teacher {
        background: #c8c8c8 !important;
    }

    .background-l-lse {
        background: #d8d8d8 !important;
    }

    .background-l-lge {
        background: #e8e8e8 !important;
    }

    .ant-progress .ant-progress-bg {
        background: #525252 !important;
        background-color: #525252 !important;
    }

    .print-border-right {
        border-right: 1px solid #d2d2d2 !important;
    }

    path:not(.domain) {
        stroke: #444 !important;
        fill: rgba(0, 0, 0, .15) !important;
    }

    circle {
        stroke: #222 !important;
        color: #222 !important;
        fill: #222 !important;
    }

    line.segment {
        stroke: #222 !important;
    }

    g rect[fill="#ff85c0"] {
        fill: #787878 !important;
    }

    g rect[fill="#b37feb"] {
        fill: #989898 !important;
    }

    g rect[fill="#95de64"] {
        fill: #b8b8b8 !important;
    }

    g rect[fill="#69c0ff"] {
        fill: #d8d8d8 !important;
    }

    .MathJax path {
        stroke: none !important;
        fill: #000 !important;
    }

    .edviz-document-v2 {
        font-size: 18px;
    }
}


/* General Styles */
.dashboard-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
    border-radius: 8px;
}

.dashboard-header {
    color: #1890ff;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: bold;
}

.section-header {
    color: #1890ff;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
}

.subsection-header {
    color: #1890ff;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
}

.teacher-header {
    color: #000;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}

.select-input {
    margin-right: 10px;
    margin-bottom: 20px;
    min-width: 200px;
}

/* Evidence Card Styles */
.evidence-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #1890ff;
}

/* Pacing Evidence Styles */
.pacing-evidence {
    display: flex;
    justify-content: space-around;
}

.pacing-quarter {
    text-align: center;
}

.pacing-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.pacing-inner-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}

.quarter-label {
    font-weight: bold;
}

/* Assessment Evidence Styles */
.assessment-evidence {
    margin-bottom: 20px;
}

.assessment-bar-container {
    margin-bottom: 10px;
}

.assessment-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.assessment-bar {
    display: flex;
    height: 20px;
    position: relative;
}

.assessment-segment {
    position: relative;
    height: 100%;
}

.assessment-segment-label {
    position: absolute;
    right: 5px;
    top: 0px;
    font-size: 12px;
    color: white;
}

/* Legend Styles */
.assessment-legend {
    display: flex;
    margin-bottom: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.legend-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 5px;
}

.legend-label {
    font-size: 14px;
}

/* Support Evidence Styles */
.support-evidence {
    margin-bottom: 20px;
}

.support-quarter {
    margin-bottom: 20px;
}

.support-tier {
    margin-bottom: 15px;
}

.tier-label {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.tier-bar {
    width: 100%;
    height: 15px;
    background-color: #e8e8e8;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
}

.tier-bar-fill {
    height: 100%;
}

.tier-bar-fill.tier-1 {
    background-color: #3fa672;
}

.tier-bar-fill.tier-2 {
    background-color: #1890ff;
}

.tier-bar-fill.tier-3 {
    background-color: #faa916;
}

.tier-bar-fill.iep {
    background-color: #f5222d;
}

/* Student Voice Evidence Styles */
.student-voice-evidence {
    margin-bottom: 20px;
}

.student-voice-quarter {
    margin-bottom: 20px;
}

.student-voice-emojis {
    display: flex;
    justify-content: space-around;
}

.emoji-item {
    text-align: center;
}

.emoji-icon {
    font-size: 50px;
}

.emoji-value {
    font-size: 18px;
    font-weight: bold;
}

/* PD Evidence Styles */
.pd-evidence {
    margin-bottom: 20px;
}

.pd-quarter {
    margin-bottom: 20px;
}

.pd-question {
    margin-bottom: 15px;
}

.question-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.question-bar {
    position: relative;
    background-color: #e8e8e8;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 5px;
}

.question-bar-fill {
    background-color: #1890ff;
    height: 100%;
}

.question-value {
    position: absolute;
    right: 5px;
    top: -20px;
    font-size: 12px;
    font-weight: bold;
}


/* PD Evidence Styles */
.pd-question {
    margin-bottom: 20px;
}

.question-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.likert-scale-bar {
    display: flex;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #e8e8e8;
    /* Fallback for no responses */
}

.likert-bar {
    height: 100%;
    display: inline-block;
    position: relative;
}

.likert-bar-label {
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 12px;
    font-weight: bold;
    color: white;
}



/* Additional Styles */
.teacher-section {
    margin-bottom: 20px;
}